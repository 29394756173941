import React, { useEffect, useState, useContext, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import {
    FormControl,
    Box,
    Stack,
    Backdrop,
    CircularProgress,
    TextField,
    Typography,
    DialogTitle,
    Dialog,
    Button,
    Fade,
    Alert,
    Snackbar,
    DialogActions,
    Grid,
    Slide,
    Toolbar,
    AppBar,
    DialogContent
} from '@mui/material'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Container } from '@mui/system';
import { Warning, PhotoCamera, Close, Delete, ArrowBackIos, CalendarMonth } from '@mui/icons-material';
import Webcam from "react-webcam";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { ProfileContext } from '../../contexts/ProfileContext';
import * as dict from '../../dictionary'
import { nanoid } from 'nanoid'
import { styled } from '@mui/material/styles';
import { useForm, Controller } from "react-hook-form";
import * as api from '../../api/Defect'
import { DialogConfirm } from '../../ui-component/index'
import { CardResponse } from '../../ui-component/index';
import cancelIcon from "../../assets/images/logo/cross.png"
import { ReactComponent as CameraIcon } from "../../assets/images/logo/cameraPlus.svg"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { useTheme } from '@mui/material/styles';
import { checkBase64 } from '../../utils/checkBase64';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export const DefectFormAdd = (props) => {
    const [dialogConfirm, setDialogConfirm] = useState(false)
    const [cameraTrigger, setCameraTrigger] = useState(false)
    const [shutterAction, setShutterAction] = useState(false)
    const [imgSaveArray, setImgSaveArray] = useState([])
    const [imgForCrop, setImgForCrop] = useState(null)
    const [cropPanelVisible, setCropPanelVisible] = useState(false)
    const [cropper, setCropper] = useState(null);
    const [countSelected, setCountSelected] = useState(0)
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [alertError, setAlertError] = useState(false)
    const [profile, setProfile] = useState(null)
    const [houseInfo, setHouseInfo] = useState(null);
    const [alertNotTransfer, setAlertNotTransfer] = useState({
        trigger: false,
        message: ""
    })
    const { control, handleSubmit, getValues, setValue, reset } = useForm();
    const [alertNoImage, setAlertNoImage] = useState(false)
    const [loadingBackDrop, setLoadingBackDrop] = useState(false)
    const fileInputRef = useRef(null);
    const context = useContext(ProfileContext)
    let navigate = useNavigate()
    let location = useLocation()
    const theme = useTheme()
    const handleCropImg = async () => {
        if (typeof cropper !== "undefined") {
            setLoadingBackDrop(true)

            const croppedCanvas = cropper.getCroppedCanvas({ width: 800, height: 600 });

            const blob = await new Promise((resolve) => {
                croppedCanvas.toBlob(resolve, "image/png", 1); // Adjust the quality if necessary
            });
    
            const imgUrl = croppedCanvas.toDataURL(); // Only generate once
            setImgSaveArray((prevArray) => [
                ...prevArray,
                {
                    id: nanoid(),
                    imgUrl,
                    binary: new File([blob], `${nanoid()}.png`, { type: "image/png" }),
                    selected: false,
                    value: ""
                }
            ]);
           
            setLoadingBackDrop(false);
            setCropPanelVisible(false)

            // setCameraTrigger(false)
        }
    }
    const messageWarning = {
        "warranty expired.": dict.Warranty_Expired_TXT[profile?.language || "th"],
        "unit isn't transfer.": dict.UNIT_NOT_TRANSFER_TXT[profile?.language || "th"],
        "": ""
    }
    const handleResponse = (response) => {
        setLoadingBackDrop(false)
        if (response?.response?.data?.status == 500 && messageWarning[response?.response?.data?.message] !== undefined) {
            setAlertNotTransfer({
                trigger: true,
                message: messageWarning[response?.response?.data?.message]
            })
        }
        if ((response?.data?.status !== 200 && response?.data?.status !== "SUCCESS") || response?.data?.status === undefined) {
            return setAlertError(true)
        }
        setAlertSuccess(true)
        return
    }
    const handleDialogSuccessClose = () => {
        setAlertSuccess(false)
        navigate(-1)
    }
    const handleOnSumbit = async () => {
        setLoadingBackDrop(true)
        if (imgSaveArray.length === 0) {
            setAlertNoImage(true)
            setLoadingBackDrop(false)
            return
        }

        let img = imgSaveArray
        let form = getValues()
        let tempInput = {}
        let res = null

        if (props?.version === "fastcare") {
            let params = new URLSearchParams(location.search);
            let unitText = params.get("unitText")
            let projectInfo
            if (checkBase64(unitText)) {
                projectInfo = window.atob(unitText)
            }

            let arrayFile = img.map(ele => ele.binary)
            res = await api.createDefectFastCare({
                client_id: parseInt(projectInfo?.split("|")[0]),
                project_id: parseInt(projectInfo?.split("|")[2]),
                unit_id: parseInt(projectInfo?.split("|")[3]),
                document_title: form.title,
                document_detail: form.detail,
                document_by_contact_name: form.contact_name,
                document_by_contact_phone: form.contact_phone,
                document_appointment_date: (form.appointment_date).toISOString(),
                photos: arrayFile
            })
            return handleResponse(res)
        }


        let tempImg = []
        await Promise.all(img.map(async item => {
            let res = await api.uploadImg(item.binary, { subject: "defect", product: "lineoa", company_id: houseInfo?.company_id })
            let data = res.data
            tempImg.push({
                "id": data.id,
                "name": data.name,
                "file_size": data.file_size,
                "content_type": data.content_type,
                "url": data.url,
                "description_en": "",
                "description_th": item.value,
                "sort_id": 0
            })
        }))

        if (location.state?.version === "fast" || houseInfo.product_type === "pmlite") {
            tempInput = {
                "line_id": profile?.line_id,
                "project_id": houseInfo?.project_id,
                "unit_id": houseInfo?.unit_id,
                "unit_number": houseInfo?.unit_number,
                "subject": form.title,
                "detail": form.detail,
                "house_number": houseInfo.house_number,
                "product_type": houseInfo.product_type,
                "file_gallery": tempImg,
                "version": location.state?.version
            }
            res = await api.createDefect(tempInput)
            return handleResponse(res)
        }

        tempInput = {
            "line_id": profile?.line_id,
            // "language": profile?.language,
            "product_type": houseInfo?.product_type,
            "project_id": houseInfo?.project_id,
            "unit_id": houseInfo?.unit_id,
            "subject": form.title,
            // "defect_type": form.type,
            "detail": form.detail,
            // "date": moment().format("YYYY-MM-DD"),
            "priority": "2",
            "fullname": profile?.language === "th" ? houseInfo?.customer_name : houseInfo?.customer_name_en,
            "unit_number": houseInfo?.unit_number,
            "house_number": houseInfo?.house_number,
            "file_gallery": tempImg
        }
        res = await api.createRemDefect(tempInput)
        return handleResponse(res)

    }

    const handleDivClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setCropPanelVisible(true)
            setImgForCrop(URL.createObjectURL(file))
        }
    };

    useEffect(() => {
        if (props?.open) {
            setImgSaveArray([])
            setCountSelected(0)
            reset()
            // setCameraTrigger(false)
        }
    }, [props?.open])
    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
        if (context.state?.houseInfo !== null) {
            setHouseInfo(context.state?.houseInfo)
        }
    }, [context])

    return (
        <>
            {
                props?.version === "fastcare" && <AppBar sx={{
                    position: 'fixed',
                    boxShadow: 'none',
                    height: 60,
                }}>
                    <Toolbar>
                        <Grid container item xs={1}>
                            <ArrowBackIos onClick={() => navigate(`/fastcare${location.search}`)} />
                        </Grid>
                        <Grid container item xs={10} direction="row"
                            justifyContent="center"
                        >
                            <Typography>
                                แจ้งซ่อม
                            </Typography>

                        </Grid>
                    </Toolbar>
                </AppBar>
            }
            <Container sx={{ marginTop: 10 }}>
                <Box
                    onSubmit={handleSubmit(handleOnSumbit)}
                    component="form"
                    id="hook-form"
                    sx={{ marginTop: 5 }}>
                    <Stack direction="column" spacing={3}>
                        <Controller
                            control={control}
                            name="title"
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <FormControl fullWidth>
                                    <TextField
                                        onChange={onChange}
                                        fullWidth label={dict.Subject_TXT[profile?.language || "th"]}
                                        id="fullWidth"
                                        InputProps={{
                                            style: {
                                                borderRadius: "5px",
                                                border: "1px"
                                            }
                                        }}
                                    />
                                    {
                                        error !== undefined && <Box sx={{ marginTop: 1 }}><Alert severity="error" >{dict.SubjectPlace_TXT[profile?.language || "th"]}</Alert></Box>
                                    }
                                </FormControl>)}
                        />

                        <Controller
                            control={control}
                            name="detail"
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <FormControl fullWidth >
                                    <TextField
                                        onChange={onChange}
                                        id="outlined-multiline-flexible"
                                        label={dict.Detail_TXT[profile?.language || "th"]}
                                        multiline
                                        rows={4}
                                        InputProps={{
                                            style: {
                                                borderRadius: "5px",
                                                border: "1px"
                                            }
                                        }}
                                    />
                                    {
                                        error !== undefined && <Box sx={{ marginTop: 1 }}><Alert severity="error">{dict.DetailPlace_TXT[profile?.language || "th"]}</Alert></Box>
                                    }
                                </FormControl>)}
                        />

                        {
                            props?.version === "fastcare" &&
                            <>
                                <Controller
                                    control={control}
                                    name="contact_name"
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                onChange={onChange}
                                                fullWidth label={dict.Reporter_Name_TXT["th"]}
                                                id="fullWidth"
                                                InputProps={{
                                                    style: {
                                                        borderRadius: "5px",
                                                        border: "1px"
                                                    }
                                                }}
                                            />
                                            {
                                                error !== undefined && <Box sx={{ marginTop: 1 }}><Alert severity="error" >{dict.Reporter_Valid_TXT["th"]}</Alert></Box>
                                            }
                                        </FormControl>)}
                                />

                                <Controller
                                    control={control}
                                    name="contact_phone"
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                onChange={onChange}
                                                fullWidth label={dict.Reporter_Phone_TXT["th"]}
                                                id="fullWidth"
                                                InputProps={{
                                                    style: {
                                                        borderRadius: "5px",
                                                        border: "1px"
                                                    }
                                                }}
                                            />
                                            {
                                                error !== undefined && <Box sx={{ marginTop: 1 }}><Alert severity="error" >{dict.Reporter_Phone_Valid_TXT["th"]}</Alert></Box>
                                            }
                                        </FormControl>)}
                                />
                            </>
                        }
                        <Box>
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <Typography fontSize={"14px"} fontWeight={"700"} color={theme.palette.primary.main}>{dict.Picture_TXT[profile?.language || "th"]}</Typography>
                                <Typography fontSize={"14px"} fontWeight={"400"} color={theme.palette.primary.main}>{imgSaveArray.length}/5</Typography>
                            </Stack>
                        </Box>
                        <Box sx={{
                            [theme.breakpoints.up("md")]: {
                                height: "150px"
                            },
                            [theme.breakpoints.down("md")]: {
                                height: "55vw"
                            }
                        }}>
                            <Grid container direction="row" justifyContent="flex-start" spacing={2} sx={{ position: "absolute", width: "100%", paddingLeft: 2 }}>
                                {
                                    imgSaveArray.map((element, key) => {
                                        return <Grid item sx={{ zIndex: 10 }} key={key}>
                                            <BoxHaveImg
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",

                                                }}>
                                                <CancelIcon src={cancelIcon} onClick={() => setImgSaveArray(imgSaveArray.filter((r, index) => r.id !== element.id))} />
                                                <Zoom>
                                                    <Box component="img" sx={{
                                                        [theme.breakpoints.up("md")]: {
                                                            width: "140px",
                                                            height: "140px",
                                                        },
                                                        [theme.breakpoints.down("md")]: {
                                                            width: "23vw",
                                                            height: "23vw",
                                                        },
                                                        objectFit: "cover",
                                                        marginTop: "4px"
                                                    }}
                                                        src={element.imgUrl}
                                                    >

                                                    </Box>
                                                    {/* <img src={element.imgUrl} sx={{
                                                        width: "23vw",
                                                        height: "23vw",
                                                        objectFit: "cover",
                                                        marginTop: "4px"
                                                    }} /> */}
                                                </Zoom>
                                            </BoxHaveImg>
                                        </Grid>
                                    })
                                }
                                {
                                    imgSaveArray.length !== 5 &&
                                    <Grid item sx={{ zIndex: 10 }}>
                                        <BoxCamera
                                            onClick={handleDivClick}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                            }}>
                                            <input
                                                id="cameraFileInput"
                                                type="file"
                                                accept="image/*"
                                                capture="environment"
                                                ref={fileInputRef}
                                                onChange={handleFileChange}
                                                style={{ display: "none" }}
                                            />
                                            <CameraIcon style={{ width: "34.94px", height: "31.44px" }} fill={theme.palette.primary.main} />
                                            <Typography fontSize={"8px"} fontWeight={700} color={theme.palette.primary.main}>{dict.ADD_PHOTO_TXT[profile?.language || "th"]}</Typography>

                                        </BoxCamera>
                                    </Grid>
                                }
                            </Grid>
                            <Grid container direction="row" justifyContent="flex-start" spacing={2} sx={{ position: "absolute", zIndex: 1, width: "100%", paddingLeft: 2 }}>
                                <Grid item >
                                    <BoxNoImg />
                                </Grid>
                                <Grid item >
                                    <BoxNoImg />
                                </Grid>
                                <Grid item >
                                    <BoxNoImg />
                                </Grid>
                                <Grid item  >
                                    <BoxNoImg />
                                </Grid>
                                <Grid item  >
                                    <BoxNoImg />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box>
                            <Typography variant={"body2"} sx={{ color: "red", marginBottom: 3 }}>*{dict.PleaseUpload_TXT[profile?.language || "th"]}</Typography>
                        </Box>
                        {
                            props?.version === "fastcare" && <Box>

                                <Controller
                                    fullWidth
                                    name="appointment_date"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                            <FormControl fullWidth >
                                                <LocalizationProvider dateAdapter={AdapterMoment}>

                                                    <MobileDateTimePicker
                                                        defaultValue={null}
                                                        label={`${dict.Defect_Appoint_Date_TXT["th"]}`}
                                                        value={value ? value : null}
                                                        // value={moment(value, "DD/MM/YYYY hh:mm")}
                                                        onChange={onChange}
                                                        renderInput={(params) => <TextField onChange={onChange} {...params} InputProps={{
                                                            endAdornment: <CalendarMonth />,
                                                        }} />}
                                                    />
                                                    {
                                                        error !== undefined && <Box sx={{ marginTop: 1 }}><Alert severity="error" >{dict.Fix_Select_Date_Book_TXT["th"]}</Alert></Box>
                                                    }
                                                </LocalizationProvider>

                                            </FormControl>
                                        </>
                                    )}
                                />
                            </Box>
                        }

                    </Stack>
                    {
                        countSelected == 0 ? <Button sx={{ mt: props?.version === "fastcare" ? 2 : 0, mb: 2, width: "100%", height: 50 }} variant={"contained"} type={"submit"}>{dict.Save_TXT[profile?.language || "th"]}</Button>
                            : <Button
                                onClick={() => {
                                    let tempSelectedFalse = []
                                    imgSaveArray.map((element, i) => {
                                        if (element.selected == false) {
                                            tempSelectedFalse.push(element)
                                        }
                                    })
                                    setImgSaveArray(tempSelectedFalse)
                                    setCountSelected(0)
                                }}
                                sx={{ marginBottom: 2, width: "100%", height: 50, color: "red" }}
                                variant={"outlined"}
                                startIcon={<Delete />}> {dict.Delete_TXT[profile?.language] || "th"} ({countSelected})</Button>
                    }
                </Box>
                <Dialog open={alertNoImage} onClose={() => setAlertNoImage(false)}>
                    <DialogTitle sx={{ color: "red" }}>{dict.UPLOAD_PHOTO_YET_TXT[profile?.language || "th"]}</DialogTitle>
                    <DialogContent>
                        <Typography variant={"subtitle1"} sx={{ color: "black" }}>{dict.UPLOAD_MINIMUM_TXT[profile?.language || "th"]}</Typography>
                    </DialogContent>
                </Dialog>
                <Snackbar open={alertError} autoHideDuration={6000} onClose={() => setAlertError(false)} >
                    <Alert severity="error">{`"${dict.WRONG_SAVE_DEFECT_TXT[profile?.language || "th"]}"`}</Alert>
                </Snackbar>
                <CardResponse
                    type={"success"}
                    titleDescription={dict.PASS_SAVE_DEFECT_TXT[profile?.language || "th"]}
                    open={alertSuccess}
                    onClose={() => {
                        handleDialogSuccessClose()
                    }}
                    onOk={() => handleDialogSuccessClose()}
                />
            </Container>
            <Dialog
                fullScreen
                open={cropPanelVisible}
                onClose={() => setCropPanelVisible(false)}
                TransitionComponent={Transition}
            >
                <Box sx={{ width: "100%", height: "100vh", marginTop: "20%" }}>
                    <Grid
                        container
                        direction="column"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={"subtitle1"}> {dict.CROP_TXT[profile?.language || "th"]} </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ width: "100%", }}>
                                <Cropper
                                    src={imgForCrop}
                                    zoomTo={0}
                                    initialAspectRatio={1}
                                    preview=".img-preview"
                                    viewMode={1}
                                    minCropBoxHeight={10}
                                    minCropBoxWidth={10}
                                    background={false}
                                    responsive={true}
                                    autoCropArea={1}
                                    checkOrientation={false}
                                    style={{ height: 400, width: "100%" }}
                                    // Cropper.js options
                                    onInitialized={(instance) => {
                                        setCropper(instance);
                                    }}
                                    guides={true}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant={"contained"}
                                onClick={() => handleCropImg()}
                            >{dict.AcceptBtn_TXT[profile?.language || "th"]}</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>

            <DialogConfirm onClose={() => {
                setDialogConfirm(false)

            }
            } onOk={() => {
                setDialogConfirm(false)
                // setCameraTrigger(false)

            }} titleText={dict.TitleDialog_TXT[profile?.language || "th"]} titleDescription={dict.Description_TXT[profile?.language || "th"]} trigger={dialogConfirm} />

            <Dialog
                fullScreen
                TransitionComponent={Transition}
                open={cameraTrigger}
            >
                <AppBar sx={{
                    position: 'relative',
                    boxShadow: 'none',
                    height: 60,
                }}>
                    <Toolbar>
                        <Grid container item xs={1}>
                            <Close onClick={() => setCameraTrigger(false)} />
                        </Grid>
                        <Grid container item xs={10} direction="row"
                            justifyContent="center"
                        >
                            <Typography variant="h7" >
                                {dict.Capture_Defect_TXT[profile?.language || "th"]}
                            </Typography>
                        </Grid>

                    </Toolbar>
                </AppBar>

                <Fade in={shutterAction}>
                    <Box sx={{ width: "100%", mt: -5 }}>
                        <Webcam
                            width="100%"
                            height={"100%"}
                            screenshotFormat="image/jpeg"
                            videoConstraints={{

                                facingMode: { exact: "environment" }
                            }}
                        >
                            {({ getScreenshot }) => (
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <Button
                                        sx={{ width: "40%", mt: 1, mb: 1 }}
                                        variant='contained'
                                        onClick={() => {
                                            const imageSrc = getScreenshot()
                                            setShutterAction(false)
                                            setTimeout(() => {
                                                setShutterAction(true)
                                                setCropPanelVisible(true)
                                            }, 100)
                                            setImgForCrop(imageSrc)
                                        }}
                                    >
                                        <PhotoCamera sx={{ fontSize: 50 }} />
                                    </Button>
                                </Box>
                            )}
                        </Webcam>
                    </Box>
                </Fade>
            </Dialog>

            <Dialog
                open={alertNotTransfer?.trigger}
                TransitionComponent={Transition}
                onClose={() => setAlertNotTransfer({
                    trigger: false,
                    message: ""
                })}
            >
                <DialogTitle > <Typography ><Stack alignItems={"center"}>
                    <Warning fontSize="large" sx={{ color: "#FFC300" }} /> {dict.CANNOT_REPORT_DEFECT_TXT[profile?.language || "th"]}
                </Stack></Typography> </DialogTitle>
                <DialogContent>{alertNotTransfer?.message}</DialogContent>
                <DialogActions>
                    <Button onClick={() => setAlertNotTransfer(false)} autoFocus>
                        {dict.AcceptBtn2_TXT[profile?.language || "th"]}
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingBackDrop}
            >
                <Stack alignItems={"center"} >
                    <Typography>{dict.Saving_Data_Defect_TXT[profile?.language || "th"]}</Typography>
                    <CircularProgress color="inherit" />
                </Stack>

            </Backdrop>

        </>
    )


}

const CancelIcon = styled('img')(({ theme }) => ({
  
    position: "absolute",
    color: "red",
    [theme.breakpoints.up('md')] : {
        transform: "translate(72px, -72px)",
        width: "30px",
        height: "30px",
    },
    [theme.breakpoints.down('md')] : {
        transform: "translate(12vw, -12vw)",
        width: "5vw",
        height: "5vw",
    },
    zIndex: 2
}))

const BoxNoImg = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        width: "150px",
        height: "150px"
    },
    [theme.breakpoints.down("md")]: {
        width: "25vw",
        height: "25vw"
    },
    background: "linear-gradient(to right, #E0E0E0 60%, transparent 0%) top repeat-x , linear-gradient(#E0E0E0 60%, transparent 60%) right repeat-y, linear-gradient(to right, #E0E0E0 60%, transparent 0%) bottom repeat-x, linear-gradient(#E0E0E0 60%, transparent 0%) left repeat-y",
    backgroundSize: "20% 3px,3px 20%",
    borderRadius: "5%"
}))

const BoxHaveImg = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        width: "150px",
        height: "150px"
    },
    [theme.breakpoints.down("md")]: {
        width: "25vw",
        height: "25vw"
    },
    background: `linear-gradient(to right, ${theme.palette.primary.main} 100%, transparent 0%) top repeat-x , linear-gradient(${theme.palette.primary.main} 100%, transparent 60%) right repeat-y, linear-gradient(to right, ${theme.palette.primary.main} 1000%, transparent 0%) bottom repeat-x, linear-gradient(${theme.palette.primary.main} 100%, transparent 0%) left repeat-y`,
    backgroundSize: "20% 3px,3px 20%",
    borderRadius: "5%"
}))

const BoxCamera = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        width: "150px",
        height: "150px"
    },
    [theme.breakpoints.down("md")]: {
        width: "25vw",
        height: "25vw"
    },
    background: `linear-gradient(to right, ${theme.palette.primary.main} 60%, transparent 0%) top repeat-x , linear-gradient(${theme.palette.primary.main} 60%, transparent 60%) right repeat-y, linear-gradient(to right, ${theme.palette.primary.main} 60%, transparent 0%) bottom repeat-x, linear-gradient(${theme.palette.primary.main} 60%, transparent 0%) left repeat-y`,
    backgroundSize: "20% 3px,3px 20%",
    borderRadius: "5%"
}))