import React from 'react'
import { Chip, Typography } from '@mui/material'
export const StatusTag = ({ text, code, lang }) => {


    const getColor = (t) => {
        switch (t) {
            case "เปิดใบงาน":
            case "open": return "#2F80ED"
            case "รอดำเนินการ":
            case "inprogress": return "#F2994A"
            case "ปิดใบงาน":
            case "complete": return "#219653"
            case "ปิดใบงาน (มีเงื่อนไข)":
            case "complete-t": return "#BB6BD9"
            case "ยกเลิก":
            case "cancel": return "#EB5757"
            default: return "#2F80ED"
        }
    }

    const getText = (c) => {
        switch (c) {
        
            case "N": return lang === "th" ? "เปิดงาน" : "Open"
            case "A":
            case "D": return lang === "th" ? "รอดำเนินการ" : "Pending"
            case "C": return lang === "th" ? "ซ่อมเสร็จ" : "Completed"
            case "T":
            case "P": return lang === "th" ? "ปิดงาน" : "Closed"
            case "R": return lang === "th" ? "ยกเลิก" : "Cancel"
            default: return "none"
        }
    }

    const getColorFromCode = (c) => {
        switch (c) {
             
            case "N": return "#eb9b9a"
            case "A":
            case "D": return "#F2994A"
            case "C": return "#2b79e5"
            case "P": return "#009e0f"
            case "R": return "#EB5757"
            case "T": return "#BB6BD9"
            default: return "#EB5757"
        }
    }


    return (
        <Chip label={<Typography fontSize={12} fontWeight={700} color={code ? getColorFromCode(code) : getColor(text)}>{code ? getText(code) : text}</Typography>} />
    )
}
