import React from "react";

export const ProfileContext = React.createContext();

const initialState = {
    profile : null,
    houseInfo : null
};


const ProfileReducer = (state, action) => {

    switch (action.type) {
        case "SET_PROFILE": return {
            ...state, profile: action.payload
        }
        case "SET_HOUSE_INFO": return {
            ...state, houseInfo: action.payload
        }
        default: return
    }
}


export const ProfileProvider = props => {
    const [state, dispatch] = React.useReducer(ProfileReducer, initialState);
    const value = { state, dispatch };

    return (
        <ProfileContext.Provider value={value}>
            {props.children}
        </ProfileContext.Provider>
    );
}

export const SET_PROFILE = "SET_PROFILE"
export const SET_HOUSE_INFO = "SET_HOUSE_INFO"
