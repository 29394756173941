import React, { useState } from 'react'
import {
    FormControl,
    Stack,
    TextField,
    Typography,
    DialogTitle,
    Dialog,
    DialogContent,
    Slide,
    Divider,
    styled,
    Button,
    Alert,
    Box
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useForm, Controller } from "react-hook-form";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { CalendarMonth } from '@mui/icons-material';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import * as dict from '../../dictionary'
import * as api from '../../api/Defect'
import { BackDropLoading } from '../../ui-component';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DefectFormChangeAppoint = (props) => {
    const { control, handleSubmit, getValues, setValue, reset } = useForm();
    const [loading, setLoading] = useState(false);
    const handleOnSumbit = async () => {
        setLoading(true)
        let form = getValues()
        let res = await api.changeDefectAppointment({
            defect_id: props?.defect_id,
            new_appointment_datetime: form.date_appoint,
            remark: form.remark
        })
        reset()
        setLoading(false)
        return props?.response(res)
    }


    return <Dialog
        fullWidth
        TransitionComponent={Transition}
        onClose={() => {
            props.onClose()
        }}
        open={props.open}

    >
        <DialogTitle sx={{ display: "flex", justifyContent: "center" }} ><Typography sx={{ fontSize: "1.5rem"}}>{props?.title}</Typography></DialogTitle>
        <Divider />
        <Box
            onSubmit={handleSubmit(handleOnSumbit)}
            component="form"
            id="hook-form"
        >
            <DialogContent>

                <Stack direction={"column"} spacing={2}>
                    <Controller
                        fullWidth
                        name="date_appoint"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                                <FormControl fullWidth >
                                    <LocalizationProvider dateAdapter={AdapterMoment}>

                                        <MobileDateTimePicker
                                            defaultValue={null}
                                            label={`${dict.Defect_Appoint_Date_TXT["th"]}`}
                                            value={value ? value : null}
                                            // value={moment(value, "DD/MM/YYYY hh:mm")}
                                            onChange={onChange}
                                            renderInput={(params) => <TextField onChange={onChange} {...params} InputProps={{
                                                endAdornment: <CalendarMonth />,
                                            }} />}
                                        />
                                        {
                                            error !== undefined && <Box sx={{ marginTop: 1 }}><Alert severity="error" >{dict.Specify_Time_Appoint_TXT["th"]}</Alert></Box>
                                        }
                                    </LocalizationProvider>

                                </FormControl>
                            </>
                        )}
                    />


                    <Controller
                        control={control}
                        name="remark"
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <FormControl fullWidth >
                                <TextField
                                    onChange={onChange}
                                    id="outlined-multiline-flexible"
                                    label={dict.Remark_TXT["th"]}
                                    multiline
                                    rows={2}
                                    InputProps={{
                                        style: {
                                            borderRadius: "5px",
                                            border: "1px"
                                        }
                                    }}
                                />
                            </FormControl>)}
                    />
                </Stack>

            </DialogContent>
            <Divider />

            <Stack spacing={1} sx={{ pl: 2, pr: 2 }} mt={2} mb={2} >
                <ButtonCustom variant="outlined" type={"submit"} sx={{ backgroundColor: "#228B22", color: "white" }} >
                    <Typography variant='body2'>
                        {dict.Save_TXT["th"]}
                    </Typography>
                </ButtonCustom>
                <ButtonCustom variant="outlined" onClick={() => {
                    props.onClose()
                }}>
                    <Typography variant='body2'>
                        {dict.Close_Window_TXT["th"]}
                    </Typography>
                </ButtonCustom>
            </Stack>
        </Box>
            <BackDropLoading loading={loading} />
    </Dialog>
}

const ButtonCustom = styled(Button)(({ theme }) => ({
    width: "100%",
    minHeight: "50px",
    borderRadius: "10px",
    border: "1px",
    boxShadow: "0px 0px 7px 0px #00000040",
    // backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.contrastText,
    "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: "white"
    }
}))