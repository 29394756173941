import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useContext } from 'react';
import { HeaderContext } from '../contexts/HeaderContext';
import { ArrowBackIosNew, Close } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import * as dict from '../dictionary'
import { EVENT_CLOSE_CLICK } from '../contexts/HeaderContext';
import { ProfileContext } from '../contexts/ProfileContext';

const HeaderChildren = () => {
    const headerContext = useContext(HeaderContext);
    const context = useContext(ProfileContext)
    const [profile, setProfile] = useState(null)
    const [arrowBackVisible, setArrowBackVisible] = useState(true)
    let location = useLocation();
    let navigate = useNavigate()
    const [headerName, setHeaderName] = useState("")
    const cutTitleByLength = location.state?.title?.length > 25 ? location.state?.title.substring(0, 25) + "..." : location.state?.title
    const pathName = {
        "/myhome": dict.HeaderMyHome_TXT[profile?.language],
        "/registration": dict.HeaderRegister_TXT["en"],
        "/registration/privacyPolicy": "Terms & Conditions",
        "/parcel": dict.HeaderParcel_TXT[profile?.language],
        "/parcel/parcelDetail": `${location.state?.postboxID}`,
        "/defect": dict.HeaderDefect_TXT[profile?.language],
        "/defectfast": dict.HeaderDefect_TXT[profile?.language],
        "/defect/defectDetail": `${location.state?.defect_id}`,
        "/defect/formAdd": dict.HeaderDefectFormAdd_TXT[profile?.language],
        "/phonebook": dict.HeaderPhoneBook_TXT[profile?.language],
        "/facility": dict.HeaderFacility_TXT[profile?.language],
        "/facility/detail": location.state?.facilityDetail ? location.state?.facilityDetail[0]?.facilitiesName : null,
        "/facility/booking": location.state?.bookingId,
        "/facility/history": location.state?.bookingId,
        "/facility/selectFacility": location.state?.detail ? location.state?.detail[0]?.facilitiesName : null,
        "/facility/formBooking": location.state?.id,
        "/facility/confirmBooking": location.state?.facilities_id,
        "/billpayment": location.state?.title,
        "/loan": dict.HeaderLoan_TXT[profile?.language],
        "/loan/result": location.state?.bank_name,
        "/chatniti": dict.HeaderContactJuristic_TXT[profile?.language],
        "/suggestion": dict.HeaderSuggestion_TXT[profile?.language],
        "/billpayment/amountPayment": dict.HeaderAmountPayment_TXT[profile?.language],
        "/billpayment/historyDetail": `${location.state?.payment_id || location.state?.contract_id}`,
        "/billpayment/bankSelecting": dict.Bank_Select_Header_TXT[profile?.language],
        "/billpayment/creditCardSelecting": dict.CreditCard_Select_Header_TXT[profile?.language],
        "/billpayment/paid": dict.HeaderPaid_TXT[profile?.language],
        "/billpayment/resultSuccess": "ชำระเงินสำเร็จ",
        "/billpayment/resultFailed": "ชำระเงินไม่สำเร็จ",
        "/projectInfo": "ข้อมูลโครงการ",
        "/projectInfo/gallery": dict.HeaderGallery_TXT[profile?.language],
        "/projectInfo/detail": location.state?.idSelected,
        "/projectInfo/plan": dict.HeaderPlan_TXT[profile?.language],
        "/projectInfo/plan/detail": location.state?.planDetail?.project_name?.length > 30 ? location.state?.planDetail?.project_name?.substring(0, 30) + "..." : location.state?.planDetail?.project_name,
        "/projectInfo/mapDialog": dict.HeaderMap_TXT[profile?.language],
        "/projectInfo/advertise": dict.HeaderAdvertise_TXT[profile?.language],
        "/projectInfo/info": dict.HeaderInfo_TXT[profile?.language],
        "/projectInfo/promotionDetail": location.state?.title?.length > 30 ? location.state?.title.substring(0, 30) + "..." : location.state?.title,
        "/projectInfo/campaignDetail": location.state?.title?.length > 30 ? location.state?.title.substring(0, 30) + "..." : location.state?.title,
        "/profile": dict.HeaderProfileDetail_TXT[profile?.language],
        "/profile/project": dict.HeaderProfileProject_TXT[profile?.language],
        "/profile/profileDetail": dict.HeaderProfileDetail_TXT[profile?.language],
        "/profile/contractInfo": location.state?.contractDetail?.house_number,
        "/profile/priceInfo": location.state?.priceInfoDetail?.house_number,
        "/profile/policy": dict.HeaderPolicy_TXT[profile?.language],
        "/profile/term": dict.HeaderTerms_TXT[profile?.language],
        "/contactsniti": dict.HeaderContactNiti_TXT[profile?.language],
        "/addressdoc": dict.HeaderAddressDelivery_TXT[profile?.language],
        "/regulations": dict.HeaderRegulation_TXT[profile?.language],
        "/regulations/detail": cutTitleByLength,
        "/adsDetail": cutTitleByLength,
        "/defectdoc": dict.HeaderDefectDoc_TXT[profile?.language],
        "/equipment": dict.HeaderEquipment_TXT[profile?.language],
        "/inspectcp": cutTitleByLength,
        "/inspectcp/status": dict.HeaderInspect_TXT[profile?.language],
        "/manualcp": cutTitleByLength,
        "/manualcp/detail": cutTitleByLength,
        "/etc": "Other services (บริการอื่นๆ)",
        "/transfercp": cutTitleByLength,
        "/transfercp/expense": dict.HeaderExpenseOnDay_TXT[profile?.language],
        "/transfercp/info": dict.HeaderTransferInfo_TXT[profile?.language],
        "/transfercp/letter": dict.HeaderLetter_TXT[profile?.language],
        "/transfercp/info/process": cutTitleByLength,
        "/loancp":cutTitleByLength,
        "/loancp/document": dict.Loan_Doc_Consider_TXT[profile?.language],
        "/loancp/document/detail": cutTitleByLength,
        "/loancp/calculate-loan": dict.Loan_Calculation_TXT[profile?.language],
        "/loancp/status": dict.Loan_Status_Menu_TXT[profile?.language],
        "/loancp/status/detail": cutTitleByLength,
        "/noprofile":"Error Report"
    }

    if(location.pathname) document.title = pathName[location.pathname] 
    
    const pathExceptVisible = [
        "/myhome",
        "/profile",
        "/language",
        "/registration",
        "/registration/privacyPolicy",
        "/billpayment",
        "/billpayment/paid",
        "/billpayment/resultSuccess",
        "/billpayment/resultFailed",
        "/projectInfo",
        "/etc",
        "/noprofile",
        "/fastcare"
    ]

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

    }, [context])

    useEffect(() => {

        if (location?.pathname && profile !== null) {
            setHeaderName(pathName[location.pathname])
        }

    }, [location, profile]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const { paperHasCloseBtn } = headerContext.state
        setArrowBackVisible(!paperHasCloseBtn)
    }, [headerContext])


    return (
        <>
            {
                pathExceptVisible.includes(location.pathname) === false &&
                <div style={{ marginBottom: 60 }}>

                    < AppBar position="fixed"
                        style={{
                            boxShadow: 'none',
                            height: 60,
                        }
                        }>

                        <Container maxWidth="xl" >

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                mt={2}
                            >

                                <Grid container item xs={1}>
                                    {arrowBackVisible &&
                                        <ArrowBackIosNew sx={{ fontSize: 25 }} onClick={() => navigate(-1)} />
                                    }
                                </Grid>


                                <Grid container item xs={10} direction="row"
                                    justifyContent="center"
                                >
                                    <Typography  noWrap>
                                        {headerName}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={1}>
                                    {
                                        headerContext.state.paperHasCloseBtn === true && <Close onClick={() => {
                                            headerContext.dispatch({
                                                type: EVENT_CLOSE_CLICK,
                                                payload: true
                                            })

                                        }} />
                                    }
                                </Grid>
                            </Grid>

                        </Container>


                    </AppBar >
                </div>

            }
        </>
    )


}

export default HeaderChildren

