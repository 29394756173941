import React, { useState } from 'react'
import {
    AppBar,
    Toolbar,
    Grid,
    Box,
    Typography
} from "@mui/material"
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import { useNavigate, useLocation } from 'react-router-dom'
import { BackDropLoading } from '../../ui-component/BackDropLoading'
export const DefectSatisfaction = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [loading, setLoading] = useState(true)

    return <>
        <AppBar sx={{
            position: 'fixed',
            boxShadow: 'none',
            height: 60,
        }}>
            <Toolbar>
                <Grid container item xs={1}>
                    <ArrowBackIos onClick={() => navigate(`/fastcare/detail${location.search}`)} />
                </Grid>
                <Grid container item xs={10} direction="row"
                    justifyContent="center"
                >
                    <Typography>
                        ประเมินความพึงพอใจ
                    </Typography>

                </Grid>
            </Toolbar>
        </AppBar>
        <Box style={{ height: "calc(100dvh - 60px)", mt: "60px" }}>
            <iframe
                style={{ height: "100%", width: "100vw" }}
                src={location?.state?.satisfaction_url}
                onLoad={() => setLoading(false)}
            >
            </iframe>
        </Box>
        <BackDropLoading
            loading={loading}
        />
    </>


}
