import React, { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CheckCircleOutlined, ErrorOutlineRounded } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { ProfileContext } from '../contexts/ProfileContext';
import * as dict from '../dictionary/CardResponse';
import { useLocation } from 'react-router-dom';
export const CardResponse = (props) => {
    const { onClose, onOk, titleDescription, open } = props
    const context = useContext(ProfileContext);
    const [profile, setProfile] = useState(null);
    const location = useLocation();
    const logoByType = (type) => {
        let logo = {
            "success": <CheckCircleOutlined sx={{ fontSize: "30px", color: "#198754" }} />,
            "error": <ErrorOutlineRounded sx={{ fontSize: "30px", color: "#FF3333" }} />
        }

        return logo[type]
    }

    const titleByType = (type) => {
        let title = {
            "success": <Typography variant='h6' color={"#198754"}>{dict.TitleSuccess_TXT[profile?.language || location.state?.language || "th"]}</Typography>,
            "error": <Typography variant='h6' color={"#FF3333"} >{dict.TitleError_TXT[profile?.language || location.state?.language || "th"]}</Typography>
        }
        return title[type]
    }

    useEffect(() => {

        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

    }, [context]);

    return (
        <>
            <Dialog
                open={(open || false)}
                onClose={() => onClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogTitle id="alert-dialog-title">
                    <Stack spacing={1} direction={"row"} alignItems={"center"}>{logoByType(props?.type)}{titleByType(props?.type)}</Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {titleDescription}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onOk()} autoFocus>
                        {dict.CardRespondAccept_TXT[profile?.language || location.state?.language] || "OK"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
