import React, { useState } from 'react'
import {
    Stack,
    TextField,
    Typography,
    DialogTitle,
    Dialog,
    DialogContent,
    Slide,
    Divider,
    styled,
    Button,
    FormControl,
    Box
} from '@mui/material'
import * as dict from '../../dictionary'
import * as api from '../../api/Defect'
import { useForm, Controller } from "react-hook-form";
import { BackDropLoading } from '../../ui-component';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DefectFormCancel = (props) => {
    const { control, handleSubmit, getValues, setValue, reset } = useForm();
    const [loading, setLoading] = useState(false);

    const checkStatus = (status) => {
        if(status === "N" || status === "D" || status === "A"){ //ถ้า เปิดงาน, รอดำเนินการ
            return "R" //ยกเลิก
        }

        return "A" // ปฏิเสธ (Reject)
    }

    const handleOnSumbit = async () => {
        setLoading(true)
        let form = getValues()
        let res = await api.saveDefectStatus({
            defect_id: props?.defect_id,
            new_defect_status: checkStatus(props?.defect_status),
            remark: form.remark
        })
        reset()
        setLoading(false)
        return props?.response(res)    }

    return <Dialog
        fullWidth
        TransitionComponent={Transition}
        onClose={() => {
            props.onClose()
        }}
        on
        open={props.open}

    >
        <DialogTitle sx={{ display: "flex", justifyContent: "center" }}><Typography sx={{ fontSize: "1.5rem"}}>{props?.title}</Typography></DialogTitle>
        <Divider />
        <Box
            onSubmit={handleSubmit(handleOnSumbit)}
            component="form"
            id="hook-form"
        >
            <DialogContent>

                <Controller
                    control={control}
                    name="remark"
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl fullWidth >
                            <TextField
                                onChange={onChange}
                                id="outlined-multiline-flexible"
                                label={dict.Remark_TXT["th"]}
                                multiline
                                rows={4}
                                InputProps={{
                                    style: {
                                        borderRadius: "5px",
                                        border: "1px"
                                    }
                                }}
                            />
                        </FormControl>)}
                />

            </DialogContent>
            <Divider />

            <Stack spacing={1} sx={{ pl: 2, pr: 2 }} mt={2} mb={2} >
                <ButtonCustom variant="outlined" type={"submit"} sx={{ backgroundColor: "#D10000", color: "white" }} >
                    <Typography variant='body2'>
                        {props?.title}
                    </Typography>
                </ButtonCustom>
                <ButtonCustom variant="outlined" onClick={() => {
                    props.onClose()
                }}>
                    <Typography variant='body2'>
                        ปิดหน้าต่าง
                    </Typography>
                </ButtonCustom>
            </Stack>
        </Box>
        <BackDropLoading loading={loading} />

    </Dialog>
}

const ButtonCustom = styled(Button)(({ theme }) => ({
    width: "100%",
    minHeight: "50px",
    borderRadius: "10px",
    border: "1px",
    boxShadow: "0px 0px 7px 0px #00000040",
    // backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.contrastText,
    "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: "white"
    }
}))