
export const SearchDefect_TXT = []
SearchDefect_TXT["en"] = "Search"
SearchDefect_TXT["th"] = "ค้นหาการแจ้งซ่อม"

export const Status_TXT = []
Status_TXT["en"] = "Status"
Status_TXT["th"] = "สถานะ"

export const Authority_TXT = []
Authority_TXT["en"] = "Authority"
Authority_TXT["th"] = "เจ้าหน้าที่"

export const Date_Create_Defect_TXT = []
Date_Create_Defect_TXT["en"] = "Report Date"
Date_Create_Defect_TXT["th"] = "วันที่แจ้ง"

export const Date_Appoint_Defect_TXT = []
Date_Appoint_Defect_TXT["en"] = "Appointment Date"
Date_Appoint_Defect_TXT["th"] = "วันที่นัดหมาย"

export const Specify_Time_Appoint_TXT = []
Specify_Time_Appoint_TXT["en"] = "Please specify appointment date."
Specify_Time_Appoint_TXT["th"] = "กรุณาระบุเวลานัด"

export const Title_Reschedule_Repair_TXT = []
Title_Reschedule_Repair_TXT["en"] = "Reschedule Repair Date"
Title_Reschedule_Repair_TXT["th"] = "เลื่อนวันนัดเข้าซ่อม"

export const Title_Reschedule_Inspect_TXT = []
Title_Reschedule_Inspect_TXT["en"] = "Reschedule Inspect Date"
Title_Reschedule_Inspect_TXT["th"] = "เลื่อนวันนัดตรวจ"

export const Button_Reschedule_TXT = []
Button_Reschedule_TXT["en"] = "Reschedule"
Button_Reschedule_TXT["th"] = "เลื่อนนัด"

export const Button_Cancel_Task_TXT = []
Button_Cancel_Task_TXT["en"] = "Cancel Task"
Button_Cancel_Task_TXT["th"] = "ยกเลิกงาน"

export const Button_NotAccept_Task_TXT = []
Button_NotAccept_Task_TXT["en"] = "Not Accepting Task (Resend Task)"
Button_NotAccept_Task_TXT["th"] = "ไม่รับงาน (แจ้งใหม่)"


export const Date_Inspect_Defect_TXT = []
Date_Inspect_Defect_TXT["en"] = "Appointment Date"
Date_Inspect_Defect_TXT["th"] = "วันที่นัดหมาย"

export const Defect_Close_By_TXT = []
Defect_Close_By_TXT["en"] = "Closing By"
Defect_Close_By_TXT["th"] = "ปิดงานโดย"


export const Button_Accept_Task_TXT = []
Button_Accept_Task_TXT["en"] = "Accept"
Button_Accept_Task_TXT["th"] = "ยอมรับ"

export const Button_Reject_TXT = []
Button_Reject_TXT["en"] = "Reject"
Button_Reject_TXT["th"] = "ปฏิเสธ"

export const Button_Satisfaction_TXT = []
Button_Satisfaction_TXT["en"] = "Satisfaction Form"
Button_Satisfaction_TXT["th"] = "ทำแบบประเมิน"


export const Defect_Date_Close_TXT = []
Defect_Date_Close_TXT["en"] = "Closing Date"
Defect_Date_Close_TXT["th"] = "วันที่ปิดงาน"


export const Repair_Date_TXT = []
Repair_Date_TXT["en"] = "Repair Date"
Repair_Date_TXT["th"] = "วันที่ซ่อมงาน"


export const Date_Report_TXT = []
Date_Report_TXT["en"] = "Report Date"
Date_Report_TXT["th"] = "แจ้งซ่อมเมื่อ"

export const More_TXT = []
More_TXT["en"] = "See More"
More_TXT["th"] = "ดูเพิ่มเติม"

export const Project_TXT = []
Project_TXT["en"] = "Project"
Project_TXT["th"] = "โครงการ"

export const Waiting_TXT = []
Waiting_TXT["en"] = "Waiting"
Waiting_TXT["th"] = "รอดำเนินการ"

export const Remark_TXT = []
Remark_TXT["en"] = "Remark"
Remark_TXT["th"] = "หมายเหตุ"

export const Detail_TXT = []
Detail_TXT["en"] = "Detail"
Detail_TXT["th"] = "รายละเอียด"

export const Picture_TXT = []
Picture_TXT["en"] = "Picture"
Picture_TXT["th"] = "รูปภาพแจ้งซ่อม"

export const Picture_After_TXT = []
Picture_After_TXT["en"] = "After Repair"
Picture_After_TXT["th"] = "รูปภาพหลังซ่อม"

export const ConfirmTitle_TXT = []
ConfirmTitle_TXT["en"] = "Are you sure ?"
ConfirmTitle_TXT["th"] = "คุณแน่ใจหรือไม่ ?"

export const ConfirmContent_TXT = []
ConfirmContent_TXT["en"] = "Confirm button will cancel the repair order."
ConfirmContent_TXT["th"] = `เมื่อคลิกปุ่ม "ยืนยัน" ระบบจะทำการยกเลิกการแจ้งซ่อมให้คุณ`

export const ConfirmAction_TXT = []
ConfirmAction_TXT["en"] = "Confirm"
ConfirmAction_TXT["th"] = `ยืนยัน`

export const CancelAction_TXT = []
CancelAction_TXT["en"] = "Cancel"
CancelAction_TXT["th"] = `ยกเลิก`

export const Reporter_TXT = []
Reporter_TXT["en"] = "Report By"
Reporter_TXT["th"] = `ผู้แจ้งซ่อม`

export const Subject_TXT = []
Subject_TXT["en"] = "Subject"
Subject_TXT["th"] = `หัวข้อเรื่อง`

export const Reporter_Name_TXT = []
Reporter_Name_TXT["en"] = "Name"
Reporter_Name_TXT["th"] = `ชื่อผู้แจ้ง`

export const Reporter_Phone_TXT = []
Reporter_Phone_TXT["en"] = "Phone Number"
Reporter_Phone_TXT["th"] = `เบอร์ติดต่อ`


export const SubjectPlace_TXT = []
SubjectPlace_TXT["en"] = "Please specify the title of this repair notice."
SubjectPlace_TXT["th"] = `กรุณาระบุชื่อเรื่องแจ้งซ่อมในครั้งนี้`

export const Reporter_Valid_TXT = []
Reporter_Valid_TXT["en"] = "Please specify the reporter of this repair notice."
Reporter_Valid_TXT["th"] = `กรุณาระบุชื่อผู้แจ้งซ่อมในครั้งนี้`

export const Reporter_Phone_Valid_TXT = []
Reporter_Phone_Valid_TXT["en"] = "Please specify the phone number of this repair notice."
Reporter_Phone_Valid_TXT["th"] = `กรุณาระบุเบอร์ติดต่อผู้แจ้งซ่อมในครั้งนี้`

export const Defect_Appoint_Date_TXT = []
Defect_Appoint_Date_TXT["en"] = "Date-time you want to make an appointment"
Defect_Appoint_Date_TXT["th"] = "วันที่-เวลาที่ต้องการนัด"

export const RepairType_TXT = []
RepairType_TXT["en"] = "Repair Report Type"
RepairType_TXT["th"] = `ประเภทการแจ้งซ่อม`

export const RepairTypePlace_TXT = []
RepairTypePlace_TXT["en"] = "Please specify the type of this repair notification."
RepairTypePlace_TXT["th"] = `กรุณาระบุประเภทการแจ้งซ่อมในครั้งนี้`

export const DetailPlace_TXT = []
DetailPlace_TXT["en"] = "Please provide details."
DetailPlace_TXT["th"] = `กรุณาระบุรายละเอียดของปัญหา`

export const Upload_TXT = []
Upload_TXT["en"] = "Upload Pictures (up to 5 photos)."
Upload_TXT["th"] = `อัพโหลดรูป (สูงสุด 5 รูป)`

export const UploadLimit_TXT = []
UploadLimit_TXT["en"] = "The full number of photos has been uploaded."
UploadLimit_TXT["th"] = `อัพโหลดรูปภาพเต็มตามจำนวนที่กำหนดแล้ว`

export const PleaseUpload_TXT = []
PleaseUpload_TXT["en"] = "Please Upload at least one image."
PleaseUpload_TXT["th"] = `โปรดอัพโหลดรูปอย่างน้อย 1 รูปภาพ`

export const Save_TXT = []
Save_TXT["en"] = "Save"
Save_TXT["th"] = `บันทึก`

export const Delete_TXT = []
Delete_TXT["en"] = "Delete"
Delete_TXT["th"] = `ลบ`

export const Close_Window_TXT = []
Close_Window_TXT["en"] = "Close Window"
Close_Window_TXT["th"] = `ปิดหน้าต่าง`

export const Defect_Type_TXT = []
Defect_Type_TXT["en"] = "Select repair type"
Defect_Type_TXT["th"] = `เลือกประเภทการแจ้งซ่อม`

export const See_Doc_Defect_TXT = []
See_Doc_Defect_TXT["en"] = "View document"
See_Doc_Defect_TXT["th"] = `ดูเอกสาร`

export const Review_Defect_TXT = []
Review_Defect_TXT["en"] = "Review"
Review_Defect_TXT["th"] = "ทำแบบประเมิน"

export const Capture_Defect_TXT = []
Capture_Defect_TXT["en"] = "Take a Photo"
Capture_Defect_TXT["th"] = "ถ่ายรูปแจ้งซ่อม"

export const CANNOT_REPORT_DEFECT_TXT = []
CANNOT_REPORT_DEFECT_TXT["en"] = "Unable to report repairs"
CANNOT_REPORT_DEFECT_TXT["th"] = "ไม่สามารถแจ้งซ่อมได้"

export const Warranty_Expired_TXT = []
Warranty_Expired_TXT["en"] = "Sorry, unable to report repairs. Because it is not covered by the warranty."
Warranty_Expired_TXT["th"] = "ขออภัยไม่สามารถแจ้งซ่อมได้ เนื่องจากไม่อยู่ในการรับประกัน"

export const UNIT_NOT_TRANSFER_TXT = []
UNIT_NOT_TRANSFER_TXT["en"] = "Because the customer has not transferred yet Therefore cannot report repairs to this room at this time."
UNIT_NOT_TRANSFER_TXT["th"] = "เนื่องจากลูกค้ายังไม่ได้โอน จึงยังไม่สามารถแจ้งซ่อมห้องนี้ได้ในขณะนี้"

export const ADD_PHOTO_TXT = []
ADD_PHOTO_TXT["en"] = "Add a photo"
ADD_PHOTO_TXT["th"] = "เพิ่มรูปภาพ"

export const UPLOAD_PHOTO_YET_TXT = []
UPLOAD_PHOTO_YET_TXT["en"] = "You haven't uploaded a picture yet."
UPLOAD_PHOTO_YET_TXT["th"] = "ท่านยังไม่ได้อัพโหลดรูป"

export const UPLOAD_MINIMUM_TXT = []
UPLOAD_MINIMUM_TXT["en"] = "Please upload at least 1 photo."
UPLOAD_MINIMUM_TXT["th"] = "โปรดอัพโหลดรูปภาพอย่างน้อย 1 รูป"

export const WRONG_SAVE_DEFECT_TXT = []
WRONG_SAVE_DEFECT_TXT["en"] = "An error occurred. We are unable to save your request at this time."
WRONG_SAVE_DEFECT_TXT["th"] = "เกิดข้อผิดพลาดเราไม่สามารถบันทึกคำร้องของคุณได้ในขณะนี้"

export const PASS_SAVE_DEFECT_TXT = []
PASS_SAVE_DEFECT_TXT["en"] = "The system has recorded your request into the repair system."
PASS_SAVE_DEFECT_TXT["th"] = "ระบบได้บันทึกคำร้องของคุณเข้าระบบแจ้งซ่อมแล้ว"

export const PASS_SAVE_NEW_APPOINT_TXT = []
PASS_SAVE_NEW_APPOINT_TXT["en"] = "The system can now record your new appointment into the repair system."
PASS_SAVE_NEW_APPOINT_TXT["th"] = "ระบบได้บันทึกนัดหมายใหม่ของคุณเข้าระบบแจ้งซ่อมแล้ว"

export const PASS_SAVE_ACCEPT_REPAIR_TXT = []
PASS_SAVE_ACCEPT_REPAIR_TXT["en"] = "The system can now record your acceptance of this repair."
PASS_SAVE_ACCEPT_REPAIR_TXT["th"] = "ระบบได้บันทึกการยอมรับการแจ้งซ่อมในครั้งนี้ของคุณแล้ว"

export const PASS_SAVE_REJECT_REPAIR_TXT = []
PASS_SAVE_REJECT_REPAIR_TXT["en"] = "You have already rejected this repair request."
PASS_SAVE_REJECT_REPAIR_TXT["th"] = "คุณได้ทำการปฏิเสธการแจ้งซ่อมในครั้งนี้เรียบร้อยแล้ว"

export const PASS_SAVE_CANCEL_REPAIR_TXT = []
PASS_SAVE_CANCEL_REPAIR_TXT["en"] = "You have successfully canceled this repair request."
PASS_SAVE_CANCEL_REPAIR_TXT["th"] = "คุณได้ทำการยกเลิกการแจ้งซ่อมในครั้งนี้เรียบร้อยแล้ว"

export const METHOD_CHOOSE_PICTURE_TXT = []
METHOD_CHOOSE_PICTURE_TXT["en"] = "Gallery"
METHOD_CHOOSE_PICTURE_TXT["th"] = "เลือกรูปภาพ"

export const METHOD_OPEN_CAMERA_TXT = []
METHOD_OPEN_CAMERA_TXT["en"] = "Camera"
METHOD_OPEN_CAMERA_TXT["th"] = "เปิดกล้อง"

export const CROP_TXT = []
CROP_TXT["en"] = "Crop Image"
CROP_TXT["th"] = "ครอบตัด"

export const Saving_Data_Defect_TXT = []
Saving_Data_Defect_TXT["en"] = "Saving data"
Saving_Data_Defect_TXT["th"] = "กำลังบันทึกข้อมูล"


export const Cannot_Get_UnitInfo_TXT = []
Cannot_Get_UnitInfo_TXT["en"] = "Something went wrong , Can not get unit information."
Cannot_Get_UnitInfo_TXT["th"] = "เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลของยูนิตนี้"

export const Cannot_Found_UnitInfoId_TXT = []
Cannot_Found_UnitInfoId_TXT["en"] = "Something went wrong , Unit not found, Please make sure your link is from qrcode."
Cannot_Found_UnitInfoId_TXT["th"] = "เกิดข้อผิดพลาด ไม่พบข้อมูลของยูนิต โปรดตรวจสอบให้แน่ใจว่าลิงค์ของคุณมาจาก Qrcode"
