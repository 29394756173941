import { useEffect, useState } from "react";
import { Typography, Box, Stack, Grid, Snackbar, Button, IconButton } from "@mui/material"
import { useTheme } from '@mui/material/styles';
import BuildIcon from '@mui/icons-material/Build';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import * as api from '../../api/Defect'
import { ModalError } from "../../ui-component";
import * as dict from '../../dictionary'
import { BackDropLoading } from "../../ui-component";
import { checkBase64 } from "../../utils/checkBase64";
export const FastCare = () => {
    const domain = window.location.origin
    const theme = useTheme()
    const location = useLocation()
    const navigate = useNavigate()
    document.title = "Fast Care"
    document.icon = "https://api.faviconkit.com/stackoverflow.com/24"
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);
    const [openDialogAddPWA, setOpenDialogAddPWA] = useState(false);
    const [loading, setLoading] = useState(false)
    const [modalError, setModalError] = useState({
        open: false,
        error: {
            status: "",
            message: ""
        }
    });
    const [unitInfo, setUnitInfo] = useState({
        project_name_en: "",
        project_name_th: "",
        unit_code: "",
        unit_no: ""
    })



    const getInfo = async (projectInfo) => {
        setLoading(true)
        let res = await api.getUnitInfoFastCare({
            client_id: projectInfo.client_id,
            unit_id: projectInfo.unit_id,
            project_id: projectInfo.project_id
        })

        if (res.status !== 200) {
            setModalError({
                open: true,
                error: {
                    status: res.response.status,
                    message: dict.Cannot_Found_UnitInfoId_TXT["th"]
                }
            })
            setLoading(false)
            return
        }

        setUnitInfo(res.data?.result)
        setLoading(false)
        return
    }

    const handleCloseDialog = () => {
        setOpenDialogAddPWA(false)
    }

    const handleAddPWA = evt => {
        evt.preventDefault();
        if (!promptInstall) {
            return;
        }
        promptInstall.prompt();
    }

    useEffect(() => {
        let params = new URLSearchParams(location.search);
        let unitText = params.get("unitText")
        let projectInfo
        if (checkBase64(unitText)) {
            projectInfo = window.atob(unitText)
        }

        getInfo({
            client_id: parseInt(projectInfo?.split("|")[0]) || null,
            project_id: parseInt(projectInfo?.split("|")[2]) || null,
            unit_id: parseInt(projectInfo?.split("|")[3]) || null
        })

    }, [])

    useEffect(() => {
        // manifest สำหรับ pwa ของ fastcare
        var myDynamicManifest = {
            "short_name": "FAST Care",
            "name": "FAST Care PWA",
            "icons": [
                {
                    "src": domain+ "/fast_icon.png",
                    "sizes": "64x64 32x32 24x24 16x16",
                    "type": "image/x-icon"
                },
                {
                    "src": domain + "/fast_icon.png",
                    "type": "image/png",
                    "sizes": "192x192"
                },
                {
                    "src": domain + "/fast_icon.png",
                    "type": "image/png",
                    "sizes": "512x512"
                }
            ],
            "start_url": domain + "/fastcare" + location.search,
            "display": "standalone",
            "theme_color": "#000000",
            "background_color": "#ffffff"
        }

        const stringManifest = JSON.stringify(myDynamicManifest);
        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);

        // เปลี่ยนไอคอน browser
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = domain + "/fast-favicon.ico";
        //=====================
    }, [])


    useEffect(() => {
        sessionStorage.removeItem("tabSelected") // ลบ tabSelected จากหน้า detail 

        const handler = e => {
            e.preventDefault();
            setSupportsPWA(true);
            setPromptInstall(e);
            setOpenDialogAddPWA(true)
        };
        window.addEventListener("beforeinstallprompt", handler);

        return () => window.removeEventListener("transitionend", handler);
    }, []);

 

    const action = (
        <>
            <Button color="secondary" size="small" onClick={handleAddPWA}>
                เพิ่ม
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseDialog}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );


    return <>
        <BoxBackground sx={{ minHeight: "calc(100dvh)" }} >
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "calc(100dvh - 90px)" }}>

                <Box sx={{ background: "rgb(255,255,255, .7)", borderRadius: "50px", padding: "50px" }}>
                    <Stack direction={"column"}>
                        {/* <Box sx={{ display: "flex", justifyContent: "center" }}> */}


                        <Grid container spacing={1} >
                            {/* Row for โครงการ */}
                            <Grid item xs={12} container justifyContent={"center"}>
                                <Typography fontSize={20} fontWeight={700} >
                                    Welcome to FAST Care
                                </Typography>
                            </Grid>
                            <Grid item xs={12} container >
                                <Grid item xs={6} sx={{ textAlign: 'right' }} >
                                    <Typography>โครงการ&nbsp;:</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ wordWrap: "break-word" }}>
                                    <Typography>&nbsp;{unitInfo?.project_name_th}</Typography>
                                </Grid>
                            </Grid>

                            {/* Row for บ้านเลขที่ */}
                            <Grid item xs={12} container>
                                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                    <Typography>บ้านเลขที่&nbsp;:</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ wordWrap: "break-word" }}>
                                    <Typography>&nbsp;{unitInfo?.unit_no}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Stack alignItems={"center"}>
                                <Typography fontSize={20} fontWeight={700} >
                                    Welcome to FAST Care
                                </Typography>
                                <Typography fontSize={20} fontWeight={700} >
                                    โครงการ{unitInfo?.project_name_th}
                                </Typography>
                                <Typography fontSize={20} fontWeight={700} >
                                    บ้านเลขที่{unitInfo?.unit_no}
                                </Typography>
                            </Stack> */}

                        {/* </Box> */}
                        <Box sx={{ display: "flex", justifyContent: "center" }} mt={5}>
                            <Stack direction={"row"} spacing={3}>
                                <Box onClick={() => navigate("/fastcare/formAdd" + location.search)}>
                                    <Stack
                                        direction="column"
                                        alignItems="center"
                                        justifyContent={"center"}
                                        spacing={1}
                                    >
                                        <Box sx={{
                                            height: "8em", width: "8em",
                                            textAlign: "center", alignItems: "center",
                                            display: "flex", justifyContent: "center",
                                            border: "1px",
                                            borderRadius: "12px",
                                            boxShadow: "5px 5px 7px 0px #00000040",
                                            backgroundColor: theme.palette.primary.main
                                        }}>
                                            <Box >
                                                <BuildIcon sx={{ color: "white" }} />
                                                <Box sx={{ textAlign: "center" }}>
                                                    <Typography variant="caption" sx={{ wordWrap: "break-word", color: "white" }}>
                                                        แจ้งซ่อม
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>

                                    </Stack>
                                </Box>

                                <Box onClick={() => navigate("/fastcare/list" + location.search + "&unitNo=" + unitInfo?.unit_no, { state: { formWelcome: true } })}>
                                    <Stack
                                        direction="column"
                                        alignItems="center"
                                        justifyContent={"center"}
                                        spacing={1}
                                    >

                                        <Box sx={{
                                            height: "8em", width: "8em",
                                            textAlign: "center", alignItems: "center",
                                            display: "flex", justifyContent: "center",
                                            border: "1px",
                                            borderRadius: "12px",
                                            boxShadow: "5px 5px 7px 0px #00000040",
                                            backgroundColor: theme.palette.primary.main
                                        }}>
                                            <Box >
                                                <ListAltOutlinedIcon sx={{ color: "white" }} />
                                                <Box sx={{ textAlign: "center" }}>
                                                    <Typography variant="caption" sx={{ wordWrap: "break-word", color: "white" }}>
                                                        รายการแจ้งซ่อม
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>

                                    </Stack>
                                </Box>
                            </Stack>

                        </Box>
                    </Stack>
                </Box>

            </Box>
            <ModalError
                onClose={() => setModalError(false)}
                open={modalError?.open}
                error={modalError?.error}
            />
            <BackDropLoading loading={loading} />
        </BoxBackground>

        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={openDialogAddPWA}
            onClose={handleCloseDialog}
            action={action}
            message="ต้องการเพิ่มหน้านี้ไว้บนหน้าจอหรือไม่?"
        // key={vertical + horizontal}
        />
    </>
}

const BoxBackground = styled(Box)({

    // background: "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(195,214,233,1) 100%) no-repeat",

    // borderRadius: "5%"
})

const BoxImg = styled(Box)({

})