import React, { useEffect, useState, useContext } from 'react'
import {
    Dialog, Typography, Card, CardContent,
    Box, Stack, Container, styled, Button, Divider, Grid,
    DialogActions, DialogContent, DialogContentText, DialogTitle,
    AppBar, Toolbar
} from '@mui/material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import {
    Status_TXT,
    Date_Report_TXT,
    Detail_TXT,
    Picture_TXT,
    ConfirmTitle_TXT,
    ConfirmContent_TXT,
    ConfirmAction_TXT,
    CancelAction_TXT,
    Reporter_TXT,
    Reporter_Phone_TXT,
    Authority_TXT,
    Date_Inspect_Defect_TXT,
    Repair_Date_TXT,
    Picture_After_TXT,
    Defect_Close_By_TXT,
    Defect_Date_Close_TXT
} from '../../dictionary/Defect';
import { useLocation } from 'react-router-dom';
import * as api from '../../api/Defect'
import * as dict from '../../dictionary'
import { ProfileContext } from '../../contexts/ProfileContext';
import { useTheme } from '@mui/material/styles';
import { StatusTag } from './StatusTag';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import moment from 'moment';
import 'moment/locale/th'
import { useNavigate } from 'react-router-dom';
import { DefectFormCancel } from './DefectFormCancel';
import { DefectFormChangeAppoint } from './DefectFormChangeAppoint';
import { CardResponse } from '../../ui-component/index';
import { BackDropLoading } from '../../ui-component/index';
import { checkBase64 } from '../../utils/checkBase64';
import { ModalError } from '../../ui-component/index';
export const DefectDetail = (props) => {

    const [detail, setDetail] = useState(null)
    const [alertDialogCancel, setAlertDialogCancel] = useState(false)
    const [profile, setProfile] = useState(null)
    const [houseInfo, setHouseInfo] = useState(null)
    const [openFormCancel, setOpenFormCancel] = useState({
        open: false,
        title: ""
    })
    const [openFormAppoint, setOpenFormAppoint] = useState({
        open: false,
        title: ""
    })
    const [modalError, setModalError] = useState({
        open: false,
        error: {
            status: "",
            message: ""
        }
    });
    const [activityDetailFast, setActivityDetailFast] = useState(null)
    const [photosFast, setPhotosFast] = useState(null)
    const [alertSuccess, setAlertSuccess] = useState({
        open: false,
        title: ""
    });
    const [loading, setLoading] = useState(false);
    const theme = useTheme()
    const context = useContext(ProfileContext)
    const location = useLocation()
    const navigate = useNavigate()
    const typeOpenReviewBtn = ["ปิดใบงาน", "complete", "ปิดใบงาน (มีเงื่อนไข)", "complete-t"]
    const handleCloseDialogCancel = () => setAlertDialogCancel(false)

    const handleDefectCancel = () => {
        handleCloseDialogCancel()
        //ทำรอไว้เฉยๆของ line น่าจะไม่ให้ทำต่อแล้วสำหรับโค้ดฟังก์ชั่นนี้
        let res = api.updateDefect({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": houseInfo?.product_type,
            "defect_id": detail.defect_id,
            "status": "Cancel",
            "remark": "ยกเลิกการแจ้งซ่อม",
        })

    }

    const handleAcceptTask = async () => {
        let res = await api.saveDefectStatus({
            defect_id: detail?.defect_id,
            new_defect_status: "P"
        })
        if (res.status === 200) {
            return setAlertSuccess({
                open: true,
                title: dict.PASS_SAVE_ACCEPT_REPAIR_TXT[profile?.language || "th"]
            })
        }

        return setModalError({
            open: true,
            error: res
        })
    }

    const conditionTitleAppoint = () => {
        if (detail?.defect_status === "N") {
            return dict.Title_Reschedule_Inspect_TXT["th"]
        }
        return dict.Title_Reschedule_Repair_TXT["th"]
    }

    const handleChangeAppoint = (res) => {

        if (res.status === 200) {
            setOpenFormAppoint(false)
            return setAlertSuccess({
                open: true,
                title: dict.PASS_SAVE_NEW_APPOINT_TXT[profile?.language || "th"]
            })
        }

        return setModalError({
            open: true,
            error: res
        })
    }

    const handleCancelTask = (res) => {

        if (res.status === 200) {
            setOpenFormCancel(false)
            return setAlertSuccess({
                open: true,
                title: detail?.defect_status === "R" ? dict.PASS_SAVE_CANCEL_REPAIR_TXT[profile?.language || "th"] : dict.PASS_SAVE_REJECT_REPAIR_TXT[profile?.language || "th"]
            })

        }

        return setModalError({
            open: true,
            error: res
        })

    }

    const handleDialogSuccessClose = () => {
        setAlertSuccess({
            open: false,
            title: ""
        })
        beforeGetDetail()
    }

    const getDetail = async (projectInfo) => {
        setLoading(true)
        let res = await api.getDefectDetailFastCare({
            client_id: projectInfo.client_id,
            defect_id: projectInfo.defect_id
        })

        if (res.status === 200) {
            const { defect_activity_detail, defect_detail, defect_photos } = res.data?.result
            setActivityDetailFast(defect_activity_detail)
            setDetail(defect_detail)
            setPhotosFast(defect_photos)
            setLoading(false)
            return
        }

        setModalError({
            open: true,
            error: res
        })
        setLoading(false)
        return

    }

    const beforeGetDetail = () => {

        let params = new URLSearchParams(location.search);
        let unitText = params.get("unitText")
        let defectId = params.get("id")
        let projectInfo
        if (checkBase64(unitText)) {
            projectInfo = window.atob(unitText)
        }

        getDetail({
            client_id: parseInt(projectInfo?.split("|")[0]) || null,
            project_id: parseInt(projectInfo?.split("|")[2]) || null,
            unit_id: parseInt(projectInfo?.split("|")[3]) || null,
            defect_id: parseInt(defectId)
        })
    }

    useEffect(() => {
        if (props?.version === "fastcare") {
            beforeGetDetail()
        }
    }, [])

    useEffect(() => {
        setDetail(location.state)
    }, [location.state])

    useEffect(() => {

        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

        if (context.state?.houseInfo !== null) {
            setHouseInfo(context.state?.houseInfo)
        }

    }, [context])


    return (
        <div>
            {
                props?.version === "fastcare" && <AppBar sx={{
                    position: 'fixed',
                    boxShadow: 'none',
                    height: 60,
                }}>
                    <Toolbar>
                        <Grid container item xs={1}>
                            <ArrowBackIos onClick={() => navigate(`/fastcare/list${location.search.replace(/&?id=\d+/, '')}`)} />
                        </Grid>
                        <Grid container item xs={10} direction="row"
                            justifyContent="center"
                        >
                            <Typography>
                                No (หมายเลขงาน)
                            </Typography>

                        </Grid>
                    </Toolbar>
                </AppBar>
            }
            <Container sx={{ mt: 8, mb: 2 }}>
                <Stack direction={"row"} justifyContent={"flex-start"}>
                    <Typography variant="h5" mt={2}> {props?.version === "fastcare" ? detail?.care_customer_title : detail?.subject} </Typography>
                </Stack>
                <Box mt={2}>
                    <Card variant="outlined" sx={{ width: "100%", boxShadow: "0px 0px 7px 0px #00000040" }}>
                        <CardContent>
                            <Stack direction={"row"} justifyContent={"space-between"} >

                                <Typography variant='caption' >
                                    {Status_TXT[profile?.language || "th"]}
                                </Typography>
                                <Typography variant='caption' >
                                    {props?.version === "fastcare" ? <StatusTag code={detail?.defect_status} lang={"th"} /> : <StatusTag text={detail?.status} />}
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"space-between"} >
                                <Typography variant='caption' >
                                    {Date_Report_TXT[profile?.language || "th"]}
                                </Typography>
                                {
                                    props?.version === "fastcare" ? <Typography variant='caption' >
                                        {(detail !== null) ? moment(detail?.defect_created_date).locale(profile?.language || "th").format('LLL') : "-"}
                                    </Typography> :
                                        <Typography variant='caption' >
                                            {(detail !== null) ? moment(detail?.issue_date).locale(profile?.language || "th").format('LLL') : "-"}
                                        </Typography>
                                }
                            </Stack>
                            <Stack direction={"row"} justifyContent={"space-between"} >

                                <Typography variant='caption' >
                                    {Reporter_TXT[profile?.language || "th"]}
                                </Typography>
                                <Typography variant='caption' >
                                    {props?.version === "fastcare" ? detail?.requester_name : detail?.customer_name}
                                </Typography>
                            </Stack>
                            {
                                props?.version === "fastcare" && <Stack direction={"row"} justifyContent={"space-between"} >

                                    <Typography variant='caption' >
                                        {Reporter_Phone_TXT[profile?.language || "th"]}
                                    </Typography>
                                    <Typography variant='caption' >
                                        {detail?.requester_mobile}
                                    </Typography>
                                </Stack>
                            }

                            <Stack mt={2} direction={"row"} justifyContent={"flex-start"} alignItems={"flex-start"}>
                                <Typography variant='h6' >
                                    {Detail_TXT[profile?.language || "th"]}
                                </Typography>
                            </Stack>
                            <Box>
                                {/* <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"flex-start"}> */}
                                <Typography variant='body2' paragraph={true} sx={{ wordWrap: "break-word" }}>

                                    {
                                        detail?.care_customer_detail
                                    }

                                </Typography>
                                {/* </Stack> */}

                            </Box>{
                                location?.state?.version === "fast" && <>
                                    <Divider sx={{ marginLeft: -14, marginRight: -14 }} />
                                    <Stack direction="row"
                                        justifyContent="center"
                                        mt={2}
                                        spacing={2}
                                    >
                                        <Button size="large"
                                            sx={{ borderRadius: "10px", boxShadow: "0px 0px 7px 0px #00000040", width: "100%" }}
                                            onClick={() => window.open(detail?.document_pdf_url)}
                                        >
                                            {dict.See_Doc_Defect_TXT[profile?.language || "th"]}
                                        </Button>
                                        {
                                            typeOpenReviewBtn.includes(detail?.status) &&
                                            <BtnCustom size="large"
                                                onClick={() => window.open(detail?.satisfaction_url)}
                                            >
                                                <Typography color={theme.palette.secondary.main}>
                                                    {dict.Review_Defect_TXT[profile?.language || "th"]}
                                                </Typography>
                                            </BtnCustom>
                                        }
                                    </Stack>
                                </>
                            }
                        </CardContent>
                    </Card>

                    {
                        props?.version === "fastcare" && <Card variant="outlined" sx={{ width: "100%", boxShadow: "0px 0px 7px 0px #00000040", mt: 2 }}>
                            <CardContent>
                                <Stack direction={"row"} justifyContent={"space-between"} >

                                    <Typography variant='caption' >
                                        {Authority_TXT[profile?.language || "th"]}
                                    </Typography>
                                    <Typography variant='caption' >
                                        {activityDetailFast?.staff[0]?.staff_username ? activityDetailFast?.staff.map((ele, i) => <div key={i}>{ele.staff_username} <br /></div>) : "-"}
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} justifyContent={"space-between"} >

                                    <Typography variant='caption' >
                                        {Date_Inspect_Defect_TXT[profile?.language || "th"]} (ล่าสุด)
                                    </Typography>
                                    <Typography variant='caption' >
                                        {(activityDetailFast?.log_appointment_lastest_date) ? moment(activityDetailFast?.log_appointment_lastest_date).locale(profile?.language || "th").format('LLL') : "-"}
                                    </Typography>
                                </Stack>


                                <Stack direction={"row"} justifyContent={"space-between"} >

                                    <Typography variant='caption' >
                                        {Repair_Date_TXT["th"]} (ล่าสุด)
                                    </Typography>
                                    <Typography variant='caption' >
                                        {activityDetailFast?.log_defect_fixed_lastest_date ? moment(activityDetailFast?.log_defect_fixed_lastest_date).locale(profile?.language || "th").format('LLL') : "-"}
                                    </Typography>
                                </Stack>

                                {
                                    detail?.defect_status === "P" && <>
                                        <Stack direction={"row"} justifyContent={"space-between"} >

                                            <Typography variant='caption' >
                                                {Defect_Close_By_TXT[profile?.language || "th"]}
                                            </Typography>
                                            <Typography variant='caption' >
                                                {activityDetailFast?.log_defect_closed_by_text || "-"}
                                            </Typography>
                                        </Stack>

                                        <Stack direction={"row"} justifyContent={"space-between"} >

                                            <Typography variant='caption' >
                                                {Defect_Date_Close_TXT[profile?.language || "th"]}
                                            </Typography>
                                            <Typography variant='caption' >
                                                {(activityDetailFast?.log_defect_closed_lastest_date !== null) ? moment(activityDetailFast?.log_defect_closed_lastest_date).locale(profile?.language || "th").format('LLL') : "-"}
                                            </Typography>
                                        </Stack>
                                    </>
                                }

                                {
                                    (detail?.defect_status === "A" || detail?.defect_status === "N" || detail?.defect_status === "D") && <Stack direction={"row"} spacing={1} sx={{ pl: 2, pr: 2 }} mt={2} >
                                        <ButtonActivity variant="outlined" onClick={() => setOpenFormAppoint({
                                            open: true,
                                            title: conditionTitleAppoint()
                                        })} >
                                            <Typography variant='body2'>
                                                {dict.Button_Reschedule_TXT["th"]}
                                            </Typography>
                                        </ButtonActivity>
                                        <ButtonActivity onClick={() => setOpenFormCancel({
                                            open: true,
                                            title: dict.Button_Cancel_Task_TXT["th"],
                                            new_defect_status: "R"
                                        })} variant="outlined" sx={{ bgcolor: "#808080", color: "white" }}>
                                            <Typography variant='body2'>
                                                {dict.CancelAction_TXT["th"]}
                                            </Typography>
                                        </ButtonActivity>
                                    </Stack>
                                }

                                {
                                    detail?.defect_status === "C" && <Stack direction={"row"} spacing={1} sx={{ pl: 2, pr: 2 }} mt={2} >
                                        <ButtonActivity variant="outlined" sx={{ bgcolor: "#4CBB17", color: "white" }} onClick={handleAcceptTask}>
                                            <Typography variant='body2'>
                                                {dict.Button_Accept_Task_TXT["th"]}
                                            </Typography>
                                        </ButtonActivity>
                                        <ButtonActivity onClick={() => setOpenFormCancel({
                                            open: true,
                                            title: dict.Button_NotAccept_Task_TXT["th"],
                                            new_defect_status: "A"
                                        })} variant="outlined" sx={{ bgcolor: "#808080", color: "white" }}>
                                            <Typography variant='body2'>
                                                {dict.Button_Reject_TXT['th']}
                                            </Typography>
                                        </ButtonActivity>
                                    </Stack>
                                }

                                {
                                    (detail?.defect_status === "P" && detail?.is_satisfaction !== "Y") && <Stack direction={"row"} spacing={1} sx={{ pl: 2, pr: 2 }} mt={2} >
                                        <ButtonActivity onClick={() => navigate("/fastcare/satisfaction" + location.search, { state: { satisfaction_url: detail?.satisfaction_url } })} variant="outlined" sx={{ backgroundColor: "#228B22", color: "white" }} >
                                            <Typography variant='body2'>
                                                {dict.Button_Satisfaction_TXT['th']}
                                            </Typography>
                                        </ButtonActivity>
                                    </Stack>
                                }

                            </CardContent>
                        </Card>
                    }

                </Box>

                {
                    props?.version === "fastcare" ? <>
                        <Box mt={2}>
                            <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                                <Typography fontSize={"14px"} fontWeight={"700"} color={theme.palette.primary.main}>{Picture_TXT[profile?.language || "th"]}</Typography>
                                <Typography fontSize={"14px"} fontWeight={"400"} color={theme.palette.primary.main} > {photosFast?.defect_photos_open?.length || 0}/5</Typography>
                            </Stack>
                        </Box>

                        <Box mt={2}>

                            <Grid container direction="row" spacing={2} >
                                {photosFast?.defect_photos_open?.map((item) => (
                                    <Grid xs={4} item sx={{ zIndex: 10 }} key={item.file_id}>
                                        <BoxHaveImg
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                paddingLeft: "4px",
                                                paddingRight: "4px"
                                            }}>
                                            <Zoom>

                                                <Box component="img"
                                                    src={item.file_url}
                                                    alt={item.file_name}
                                                    sx={{
                                                        width: "100%", height: "100%", objectFit: "cover", marginTop: "4px"
                                                    }}
                                                >

                                                </Box>
                                            </Zoom>
                                        </BoxHaveImg>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>

                    </> :
                        <>
                            <Box mt={2}>
                                <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                                    <Typography fontSize={"14px"} fontWeight={"700"} color={theme.palette.primary.main}>{Picture_TXT[profile?.language || "th"]}</Typography>
                                    <Typography fontSize={"14px"} fontWeight={"400"} color={theme.palette.primary.main} > {detail?.file_gallery?.length || 0}/5</Typography>
                                </Stack>
                            </Box>
                            <Box mt={2}>

                                <Grid container direction="row" spacing={2} >
                                    {detail?.file_gallery?.map((item) => (
                                        <Grid xs={4} item sx={{ zIndex: 10 }} key={item.img}>
                                            <BoxHaveImg
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    paddingLeft: "4px",
                                                    paddingRight: "4px"
                                                }}>
                                                <Zoom>
                                                    <img src={item.url} alt={item.description_th} style={{ width: "100%", height: "100%", objectFit: "cover", marginTop: "4px" }} />
                                                </Zoom>
                                            </BoxHaveImg>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>

                        </>

                }


                {
                    props?.version === "fastcare" && <><Box mt={2}>
                        <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                            <Typography fontSize={"14px"} fontWeight={"700"} color={theme.palette.primary.main}>{Picture_After_TXT[profile?.language || "th"]}</Typography>
                            <Typography fontSize={"14px"} fontWeight={"400"} color={theme.palette.primary.main} > {photosFast?.defect_photos_after?.length || 0}/5</Typography>
                        </Stack>
                    </Box>

                        <Box mt={2}>

                            <Grid container direction="row" spacing={2} >
                                {photosFast?.defect_photos_after?.map((item) => (
                                    <Grid xs={4} item sx={{ zIndex: 10 }} key={item.file_id}>
                                        <BoxHaveImg
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                paddingLeft: "4px",
                                                paddingRight: "4px"
                                            }}>
                                            <Zoom>
                                                <img src={item.file_url} alt={item.file_name} style={{ width: "100%", height: "100%", objectFit: "cover", marginTop: "4px" }} />
                                            </Zoom>
                                        </BoxHaveImg>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </>
                }

            </Container>
            <Dialog
                open={alertDialogCancel}
                onClose={() => setAlertDialogCancel(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {ConfirmTitle_TXT[profile?.language || "th"]}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {ConfirmContent_TXT[profile?.language || "th"]}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogCancel} >{CancelAction_TXT[profile?.language || "th"]}
                    </Button>
                    <Button variant={"outlined"} onClick={handleDefectCancel} color="error">
                        {ConfirmAction_TXT[profile?.language || "th"]}
                    </Button>
                </DialogActions>
            </Dialog>

            <DefectFormCancel defect_id={detail?.defect_id} defect_status={detail?.defect_status} title={openFormCancel.title} open={openFormCancel.open} response={handleCancelTask} onClose={() => setOpenFormCancel({
                open: false,
                title: ""
            })} />
            <DefectFormChangeAppoint defect_id={detail?.defect_id} title={openFormAppoint.title} open={openFormAppoint.open} response={handleChangeAppoint} onClose={() => setOpenFormAppoint({
                open: false,
                title: ""
            })} />

            <CardResponse
                type={"success"}
                titleDescription={alertSuccess.title}
                open={alertSuccess.open}
                onClose={handleDialogSuccessClose}
                onOk={handleDialogSuccessClose}
            />

            <ModalError
                onClose={() => setModalError({
                    open: false,
                    error: {
                        status: "",
                        message: ""
                    }
                })}
                open={modalError.open}
                error={modalError.error}
            />

            <BackDropLoading loading={loading} />
        </div >
    )
}

const BoxHaveImg = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "100%",
    background: `linear-gradient(to right, ${theme.palette.primary.main} 100%, transparent 0%) top repeat-x , linear-gradient(${theme.palette.primary.main} 100%, transparent 60%) right repeat-y, linear-gradient(to right, ${theme.palette.primary.main} 1000%, transparent 0%) bottom repeat-x, linear-gradient(${theme.palette.primary.main} 100%, transparent 0%) left repeat-y`,
    backgroundSize: "20% 3px,3px 20%",
    borderRadius: "5%"
}))

const BtnCustom = styled(Button)(({ theme }) => ({
    borderRadius: "10px",
    boxShadow: "0px 0px 7px 0px #00000040",
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
        backgroundColor: theme.palette.primary.main

    }
}))

const ButtonActivity = styled(Button)(({ theme }) => ({
    width: "100%",
    minHeight: "50px",
    borderRadius: "10px",
    border: "1px",
    boxShadow: "0px 0px 7px 0px #00000040",
    // backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.contrastText,
    "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: "white"
    }
}))