import {
    Stack, Card, Container, Typography,
    Box, Tabs, Tab, CardContent, Fab,
    Button, Slide, Input, CircularProgress, Backdrop,
    AppBar, Toolbar, Grid
} from '@mui/material'
import React from 'react';
import { Add, HolidayVillage, Search, Close, ArrowBackIos } from '@mui/icons-material';
import { useEffect, useState, useContext, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import * as api from '../../api'
import * as dict from '../../dictionary'
import { ProfileContext } from '../../contexts/ProfileContext';
import { BlankPage, ModalError } from '../../ui-component';
import { StatusTag } from './StatusTag';
import moment from 'moment';
import { ChipRoomInfo } from '../../ui-component/ChipRoomInfo';
import { checkBase64 } from '../../utils/checkBase64';
export const Defect = (props) => {

    const [tabSelected, setTabSelected] = useState(sessionStorage.getItem("tabSelected") || "waiting")
    const [defectList, setDefectList] = useState([])
    const [triggerSearch, setTriggerSearch] = useState(false)
    const [loadingList, setLoadingList] = useState(false)
    const [profile, setProfile] = useState(null)
    const [houseInfo, setHouseInfo] = useState(null);
    const [modalError, setModalError] = useState(false);
    const [error, setError] = useState(null);
    const [keyword, setKeyword] = useState(null);
    const [clientId, setClientId] = useState(null);
    const context = useContext(ProfileContext)
    const location = useLocation()
    const navigate = useNavigate()
    let params = new URLSearchParams(location.search);
    let unitText = params.get("unitText")
    let unitNo = params.get("unitNo")
    const loadDataList = async (defect_status_fast_care) => {

        const conditionGet = async () => {
            if (props?.version === "fastcare") {
                let client_id, unit_id, project_id
                if (checkBase64(unitText)) {
                    let projectInfo = window.atob(unitText)
                    client_id = parseInt(projectInfo.split("|")[0])
                    setClientId(client_id)
                    project_id = parseInt(projectInfo.split("|")[2])
                    unit_id = parseInt(projectInfo.split("|")[3])
                }


                return await api.getDefectListFastCare({
                    "client_id": client_id,
                    "project_id": project_id,
                    "unit_id": unit_id,
                    "defect_status": defect_status_fast_care || "P", // P = รอดำเนินการ, H = ประวัติ
                    "search_keyword": keyword
                })

            }

            return await api.getAllDefectList({
                "line_id": profile?.line_id,
                "product_type": houseInfo?.product_type,
                "project_id": houseInfo?.project_id,
                "unit_number": houseInfo?.unit_number,
                "keyword": keyword,
                "version": props?.version
            })

        }

        setLoadingList(true)


        let res = await conditionGet()
        if (res.status === 200) {
            setDefectList(props?.version === "fastcare" ? res.data?.result : res.data?.data)
            setLoadingList(false)
            return
        }
        setError(res)
        setModalError(true)
        setLoadingList(false)
        return
    }

    const loadDataHistory = async () => {
        if (props?.version === "fastcare") {
            return loadDataList("H")
        }


        setLoadingList(true)

        let res = await api.getDefectHistory({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": houseInfo?.product_type,
            "project_id": houseInfo?.project_id,
            "unit_number": houseInfo?.unit_number,
            "keyword": keyword,
            "version": props?.version
        })

        if (res.status === 200) {
            setDefectList(res.data.data)
            setLoadingList(false)
            return
        }
        setError(res)
        setModalError(true)
        return
    }

    const loadDetail = async (defect_id) => {

        let res
        if (props?.version === "fastcare") {
            res = await api.getDefectDetailFastCare({
                client_id: clientId,
                defect_id: defect_id
            })
            return res?.data
        }


        res = await api.getDefectDetail({
            "line_id": profile?.line_id,
            "defect_id": defect_id,
            "project_id": houseInfo?.project_id,
            "house_number": houseInfo?.house_number,
            "product_type": houseInfo?.product_type,
            "version": props?.version
        })

        return res?.data
    }


    const handleClickDetail = async (defect_id) => {

        if (props?.version === "fastcare") {
            return navigate('/fastcare/detail' + location.search + `&id=${defect_id}`)
        }

        let data = await loadDetail(defect_id)

        navigate('/defect/defectDetail' + location.search, {
            state: {
                ...data?.data,
                version: props?.version
            }
        })
        return

    }

    const handleSelectedTab = (tab) => {
        window.scrollTo(0, 0);
        setDefectList([])
        if (tab === "history") {
            return loadDataHistory()
        }

        return handleChangeHouseInfo()
    }

    const handleChangeHouseInfo = () => {
        if (houseInfo == null && props?.version !== "fastcare") {
            return
        }
        return loadDataList()
    }

   

    useEffect(() => {
        handleSelectedTab(sessionStorage.getItem("tabSelected") || tabSelected)

    }, [tabSelected, houseInfo])

    useEffect(() => {

        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

        if (context.state?.houseInfo !== null) {
            setHouseInfo(context.state?.houseInfo)
        }

    }, [context])




    useEffect(() => {
        if (keyword !== null) {
            const apiCallTimeoutId = setTimeout(() => {
                if (houseInfo !== null || props?.version === "fastcare") {
                    handleSelectedTab(tabSelected)
                }
            }, 1000);
            return () => clearTimeout(apiCallTimeoutId);
        }

    }, [keyword]);




    // From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js


    return (
        <>    {
            props?.version === "fastcare" && <AppBar sx={{
                position: 'fixed',
                boxShadow: 'none',
                height: 60,
            }}>
                <Toolbar>
                    <Grid container item xs={1}>
                        <ArrowBackIos onClick={() => navigate(`/fastcare${location.search.split("&")[0]}`)} />
                    </Grid>
                    <Grid container item xs={10} direction="row"
                        justifyContent="center"
                    >
                        <Typography>
                            รายการซ่อม
                        </Typography>

                    </Grid>
                </Toolbar>
            </AppBar>
        }
            <Box sx={{ minHeight: "100dvh", mt: props?.version === "fastcare" ? "60px" : "0px" }} >
                <Box sx={{ minWidth: "100%", position: "fixed", zIndex: 10 }}>

                    <Tabs
                        value={tabSelected}
                        onChange={(e, val) => {
                            sessionStorage.setItem('tabSelected', val)
                            setTabSelected(val)
                        }}
                        textColor="secondary"
                        indicatorColor="secondary"
                        centered
                        variant="fullWidth"
                        sx={{ backgroundColor: "white" }}
                    >
                        <Tab value="waiting" label={dict.Waiting_TXT[profile?.language || "th"]} />
                        <Tab value="history" label={dict.History_TXT[profile?.language || "th"]} />

                    </Tabs>
                </Box>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loadingList}
                >
                    <Stack alignItems={"center"} >
                        <CircularProgress color="inherit" />
                    </Stack>

                </Backdrop>

                <Container sx={{ minHeight: "100dvh", paddingBottom: 4 }} >
                    <Box sx={{ width: "100%", overflow: "hidden" }} >
                        <Stack direction="row" mt={8}   >
                            <Slide direction="right" in={!triggerSearch} mountOnEnter unmountOnExit>
                                <Stack sx={{ width: "100%" }} direction={"row"} alignItems="center" justifyContent={"space-between"}>
                                    <ChipRoomInfo
                                        houseInfo={props?.version === "fastcare" ? { unit_number: unitNo } : houseInfo}
                                        icon={<HolidayVillage />}
                                        lang={profile?.language || "th"}
                                        version={props?.version}
                                    />
                                    <Button size="small">
                                        <Search sx={{ fontSize: 35 }} onClick={() => setTriggerSearch(true)} />
                                    </Button>
                                </Stack>
                            </Slide >
                        </Stack>
                        <Slide direction="left" timeout={{ enter: 500, exit: 500 }} in={triggerSearch} mountOnEnter unmountOnExit>
                            <Box sx={{ width: "100%" }}>
                                <Input placeholder={dict.SearchDefect_TXT[profile?.language || "th"]} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                                <Button size="small">
                                    <Close onClick={() => {
                                        setTriggerSearch(false)
                                        setKeyword("")
                                    }} />
                                </Button>
                            </Box>
                        </Slide >
                    </Box>
                    <Box mt={2}>
                        <Stack spacing={1}>
                            {
                                defectList?.length > 0 ? defectList?.map((element, i) => <Card key={i} sx={{ minWidth: 275, borderRadius: "10px", boxShadow: "0px 0px 7px 0px #00000040" }}>
                                    <CardContent >
                                        <Stack direction="column"
                                            spacing={1}
                                        >
                                            <Typography fontSize={20} fontWeight={700} >
                                                {element.subject}
                                            </Typography>

                                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                <Typography variant="caption" >
                                                    {dict.Status_TXT[profile?.language || "th"]}
                                                </Typography>
                                                {props?.version === "fastcare" ? <StatusTag code={element?.status} lang={"th"} /> : <StatusTag text={element?.status} />}
                                            </Stack>
                                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={2}>
                                                <Typography variant="caption" >
                                                    {dict.Date_Create_Defect_TXT[profile?.language || "th"]}
                                                </Typography>
                                                <Typography fontSize={12} fontWeight={700} >
                                                    {moment(element.issue_date).format("DD/MM/YYYY")}
                                                </Typography>
                                            </Stack>
                                            {
                                                props?.version === "fastcare" && <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={2}>
                                                    <Typography variant="caption" >
                                                        {dict.Date_Appoint_Defect_TXT[profile?.language || "th"]}
                                                    </Typography>
                                                    <Typography fontSize={12} fontWeight={700} >
                                                        {moment(element.appointment_datetime).format("DD/MM/YYYY")}
                                                    </Typography>
                                                </Stack>
                                            }
                                        </Stack>
                                        <Stack direction="row"
                                            justifyContent="center"
                                            mt={2}
                                        >
                                            <Button size="large"
                                                onClick={() => handleClickDetail(element.defect_id)}
                                                sx={{ borderRadius: "10px", boxShadow: "0px 0px 7px 0px #00000040", width: "215.44px" }}>
                                                {dict.More_TXT[profile?.language || "th"]}
                                            </Button>
                                        </Stack>
                                    </CardContent>
                                </Card>) : <BlankPage lang={profile?.language || "th"} />
                            }
                        </Stack>
                    </Box>
                    {
                        (tabSelected === "waiting" && props?.version !== "fastcare") && <Box
                            style={{
                                margin: 0,
                                top: 'auto',
                                right: 20,
                                bottom: 20,
                                left: 'auto',
                                position: 'fixed',
                            }}>
                            <Fab
                                variant="primary"
                                size='large'
                                color="secondary"
                                onClick={() => {
                                    navigate('/defect/formAdd' + location.search, { state: { version: props?.version } })
                                }}
                            >
                                <Add />

                            </Fab>
                        </Box>
                    }
                </Container>

            </Box>
            <ModalError
                onClose={() => setModalError(false)}
                open={modalError}
                error={error}
            />
        </>
    )
}