import axios from "axios";



export const getDefectHistory = async (data) => {
    const checkPathApi = () => {
        if (data.version === "fast") {
            return "fastinspect/document/history/list"
        }

        if (data.product_type === "remlite") {
            return "defectrem/history"
        }

        if (data.product_type === "rental") {
            return "defectrental/history"
        }

        return "defect/history"
    }

    try {

        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/${checkPathApi()}`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "project_id": data.project_id,
                "unit_number": data.unit_number,
                "keyword": data.keyword
            }
        })

        return result

    } catch (error) {
        return error
    }
}



export const getAllDefectList = async (data) => {

    const checkPathApi = () => {
        if (data.version === "fast") {
            return "fastinspect/document/list"
        }

        if (data.product_type === "remlite") {
            return "defectrem"
        }

        if (data.product_type === "rental") {
            return "defectrental"
        }

        return "defect"
    }

    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/${checkPathApi()}`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "project_id": data.project_id,
                "unit_number": data.unit_number,
                "keyword": data.keyword
            }
        })

        return result
    } catch (error) {
        return error
    }

}

export const getDefectListFastCare = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_FAST_CARE_HOST_API}/care/get-defect-by-unit`,
            data: {
                "client_id": data.client_id,
                "project_id": data.project_id,
                "unit_id": data.unit_id,
                "defect_status": data.defect_status, // P = รอดำเนินการ, H = ประวัติ
                "search_keyword": data.search_keyword
            }
        })

        return result
    } catch (error) {
        return error
    }
}

export const getDefectDetail = async (data) => {

    const checkPathApi = () => {
        if (data.version === "fast") {
            return "fastinspect/document/detail"
        }

        if (data.product_type === "remlite") {
            return "defectrem/detail"
        }

        if (data.product_type === "rental") {
            return "defectrental/detail"
        }

        return "defect/detail"
    }

    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/${checkPathApi()}`,
            data: {
                "line_id": data.line_id,
                "defect_id": data.defect_id,
                "project_id": data.project_id,
                "house_number": data.house_number,
                "product_type": data.product_type
            }
        })
        return result

    } catch (error) {
        return error
    }
}

export const getDefectRemDetail = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/defectrem/detail`,
            data: {
                "line_id": data.line_id,
                "defect_id": data.defect_id,
                "project_id": data.project_id,
                "house_number": data.house_number,
                "language": data.language,
                "product_type": data.product_type
            }
        })
        return result

    } catch (error) {
        return error
    }
}

export const getDefectDetailFastCare = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_FAST_CARE_HOST_API}/care/get-defect-detail-by-defect-id`,
            data: {
                "client_id": data.client_id,
                "defect_id": data.defect_id
            }
        })

        return result
    } catch (error) {
        return error
    }
}

export const createDefect = async (data) => {
    let pathApi = data.version === "std" ? "/lineoa/defect/create" : "/lineoa/fastinspect/document/create"
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}${pathApi}`,
            data: {
                "line_id": data.line_id,
                "project_id": data.project_id,
                "unit_id": data.unit_id,
                "unit_number": data.unit_number,
                "subject": data.subject,
                "detail": data.detail || "",
                "house_number": data.house_number,
                "product_type": data.product_type,
                "file_gallery": data.file_gallery || []
            }

        })
        return result

    } catch (error) {
        return error
    }
}

export const createRemDefect = async (data) => {

    const checkPathApi = () => {
        if (data.product_type === "rental") {
            return "defectrental"
        }
        return "defectrem"
    }


    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/${checkPathApi()}/create`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "project_id": data.project_id,
                "unit_id": data.unit_id,
                "subject": data.subject,
                // "defect_type":data.defect_type,
                "detail": data.detail || "",
                "date": data.date,
                "priority": data.priority,
                "fullname": data.fullname,
                "unit_number": data.unit_number,
                "house_number": data.house_number,
                "file_gallery": data.file_gallery || []
            }

        })
        return result

    } catch (error) {
        return error
    }
}


export const createDefectFastCare = async (data) => {
    const formData = new FormData();
    formData.append("client_id", data.client_id);
    formData.append("project_id", data.project_id);
    formData.append("unit_id", data.unit_id);
    formData.append("document_title", data.document_title);
    formData.append("document_detail", data.document_detail);
    formData.append("document_by_contact_name", data.document_by_contact_name);
    formData.append("document_by_contact_phone", data.document_by_contact_phone);
    formData.append("document_appointment_date", data.document_appointment_date);

    for(let i = 0;i<data.photos.length; i++){
        formData.append("photos", data.photos[i]);
    }

    try {
        let result = await axios.post(`${process.env.REACT_APP_FAST_CARE_HOST_API}/care/create-document`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return result

    } catch (error) {
        return error
    }
}


export const updateDefect = async (data) => {
    try {
        let result = await axios({
            method: 'put',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/defect/update`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "defect_id": data.defect_id,
                "status": data.status,
                "remark": data.remark,
            }
        })
        return result

    } catch (error) {
        return error
    }
}

export const uploadImg = async (data, type) => {
    const formData = new FormData();
    formData.append("data", data);
    formData.append("type", JSON.stringify(type))

    try {
        let result = await axios.post(`${process.env.REACT_APP_LINEOA_V2_HOST_API}/files`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return result

    } catch (error) {
        return error
    }
}

export const getDefectChoice = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/defect/choice`,
            data: {
                "project_type": data.project_type,
                "language": data.language
            }
        })
        return result

    } catch (error) {
        return error
    }
}

export const saveDefectStatus = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_FAST_CARE_HOST_API}/care/save-defect-status`,
            data: {
                "defect_id": data.defect_id,
                "new_defect_status": data.new_defect_status, // R = ยกเลิก, P = ปิดงาน, A = ปฎิเสธ
                "remark": data.remark
            }
        })
        return result

    } catch (error) {
        return error
    }
}

export const changeDefectAppointment = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_FAST_CARE_HOST_API}/care/change-defect-appointment`,
            data: {
                "defect_id": data.defect_id,
                "new_appointment_datetime": data.new_appointment_datetime,
                "remark": data.remark
            }
        })
        return result

    } catch (error) {
        return error
    }
}

export const getUnitInfoFastCare = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_FAST_CARE_HOST_API}/care/get-unit-info`,
            data: {
                "client_id": data.client_id,
                "project_id": data.project_id,
                "unit_id": data.unit_id
            }
        })
        return result
    } catch (error) {
        return error        
    }
}